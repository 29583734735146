import { DateTime, Interval } from "luxon";

/**
 * Default SCP date format
 */
const dateFormat = "dd/MM/yyyy";
const datePostFormat = "yyyy-MM-dd";
//const dateTimePostFormat = "YYYY-MM-DDThh:mm:ssZ";

/**
 * Coverts from ISO string to date format
 */
export const formatISODate = (format: string) => (date?: any) =>
    date ? DateTime.fromISO(date).toFormat(format) : "";

export const formatDate = (format: string) => (date?: any) =>
    date ? date.toFormat(format) : DateTime.now().toFormat(format);

//displayDate("2022-05-10") => 2022/05/10
export const displayDate = formatISODate(dateFormat);

export const isValidDate = (date: string) =>
    typeof date === "string" && DateTime.fromISO(date).isValid;

/**
 * Convert from ISO and use it as MUI picker value
 */
export const datePlaceholder = (date?: string, days?: number) => {
    if (!date) return;

    return days
        ? DateTime.fromISO(date).plus({ days: 1 })
        : DateTime.fromISO(date);
};

/**
 * Convert date for API post request
 */
export const convertDateForAPI = formatDate(datePostFormat);
//export const convertDateTimeForAPI = formatDate(dateTimePostFormat);

export const defaultStartMinDate = () => DateTime.now().minus({ years: 1 });
export const defaultEndMinDate = () =>
    DateTime.now().minus({ years: 1 }).plus({ days: 1 });

export const setInitialDate = (
    action?: string,
    limiter?: { days?: number; years?: number; months?: number },
    format?: string,
) => {
    const defaultDate = DateTime.now();
    if (!action || !limiter)
        return defaultDate.toFormat(format ? format : datePostFormat);
    if (action === "minus") {
        return defaultDate
            .minus(limiter)
            .toFormat(format ? format : datePostFormat);
    }
    if (action === "plus") {
        return defaultDate
            .plus(limiter)
            .toFormat(format ? format : datePostFormat);
    }
};

export const isValidDateRange = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) return;

    return DateTime.fromISO(startDate) <= DateTime.fromISO(endDate);
};

export const getQuarterStartDate = (date: DateTime): DateTime => {
    const month = date.month;

    let quarterStartMonth;
    if (month >= 1 && month <= 3) {
        quarterStartMonth = 1;
    } else if (month >= 4 && month <= 6) {
        quarterStartMonth = 4;
    } else if (month >= 7 && month <= 9) {
        quarterStartMonth = 7;
    } else {
        quarterStartMonth = 10;
    }

    return date.set({ month: quarterStartMonth, day: 1 });
};

function getQuarterEndDate(date: DateTime, quarters: number) {
    //03-09-2024

    const quarterStartDate = getQuarterStartDate(date);

    return quarterStartDate.plus({ months: quarters * 3 }).minus({ days: 1 });
}

export const generateQuarters = (
    startDateStr: string,
    endDateStr: string,
    startKey: string,
    endKey: string,
    quarters: number,
) => {
    const newRanges: Record<string, string | null>[] = [];
    let currentStartDate = DateTime.fromISO(startDateStr);
    const providedEndDate = DateTime.fromISO(endDateStr);

    while (currentStartDate <= providedEndDate) {
        const newStartDateStr = currentStartDate.toISODate();
        let newEndDate = getQuarterEndDate(currentStartDate, quarters);

        // Ensure the new end date does not exceed the provided end date
        if (newEndDate > providedEndDate) {
            newEndDate = providedEndDate;
        }

        const newEndDateStr = newEndDate.toISODate();

        newRanges.push({
            [startKey]: newStartDateStr,
            [endKey]: newEndDateStr,
        });

        // Move to the next quarter
        currentStartDate = newEndDate.plus({ days: 1 });

        // Break the loop if the next start date exceeds the provided end date
        if (currentStartDate > providedEndDate) {
            break;
        }
    }

    return newRanges;
};

export const calculateAllowedDateRanges = (allowedScaleRange, scales) => {
    // Convert date strings to DateTime objects
    const allowedStart = DateTime.fromISO(allowedScaleRange.periodFrom); //2024-09-09
    const allowedEnd = DateTime.fromISO(allowedScaleRange.periodTo); // 2025-09-09

    // Sort scales by periodFrom
    scales.sort((a, b) =>
        DateTime.fromISO(a.periodFrom) < DateTime.fromISO(b.periodFrom)
            ? -1
            : 1,
    );

    const remainingRanges: Array<{
        periodFrom: string;
        periodTo: string;
    }> = [];

    let currentStart: any = allowedStart;

    for (const scale of scales) {
        const scaleStart = DateTime.fromISO(scale.periodFrom).startOf("day");
        const scaleEnd = DateTime.fromISO(scale.periodTo).startOf("day");

        // If there's a gap before the current scale, add it to remaining ranges
        if (currentStart < scaleStart) {
            remainingRanges.push({
                periodFrom: currentStart.toISODate(),
                periodTo: scaleStart.minus({ days: 1 }).toISODate() ?? "",
            });
        }

        // Move the currentStart to after the current scale
        currentStart = Interval.fromDateTimes(
            currentStart,
            scaleEnd.plus({ days: 1 }),
        ).end;
    }

    // If there's remaining time after the last scale, add it
    if (currentStart <= allowedEnd) {
        remainingRanges.push({
            periodFrom: currentStart.toISODate(),
            periodTo: allowedEnd.toISODate() ?? "",
        });
    }

    // Return the first remaining range, or null if no ranges are left
    return remainingRanges.length > 0
        ? remainingRanges[0]
        : { periodFrom: "", periodTo: "" };
};

export const subtractWorkingDays = (
    startDate: DateTime,
    workingDays: number | string,
) => {
    // Convert input to Luxon DateTime if it isn't already
    const date = DateTime.isDateTime(startDate)
        ? startDate
        : DateTime.fromISO(startDate);

    let daysToSubtract = workingDays;
    let currentDate = date;

    if (typeof daysToSubtract === "string") {
        daysToSubtract = parseInt(daysToSubtract, 10);
    }

    while (daysToSubtract > 0) {
        // Move one day back
        currentDate = currentDate.minus({ days: 1 });

        // Check if it's a weekday (1-5 are Monday-Friday)
        const dayOfWeek = currentDate.weekday;

        // Only count if it's a weekday
        if (dayOfWeek >= 1 && dayOfWeek <= 5) {
            daysToSubtract--;
        }
    }

    return currentDate;
};
