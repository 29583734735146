// Lib
import { Typography, Box } from "@mui/material";

import React, {
    Fragment,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import cloneDeep from "lodash.clonedeep";
import { useRecoilState } from "recoil";

// Own components
import { GrayBox } from "@components";

// Hooks
import { useUpdateStep } from "@hooks";

// Utils
import { isArrayWithContent } from "@utils";

// Atoms
import { selectClaimsState, selectContractsState } from "@atoms";

// Own components
import AssignClaimsModal from "./AssignClaimsModal";

/**
 * Props type
 */
interface Props {
    flowState?: string;
    location?: Location;
}

/**
 * Select Claims
 */
const SelectClaims = React.forwardRef(({ flowState, location }: Props, ref) => {
    /**
     * Selected contracts
     */
    const [selectedContracts, updateContracts] =
        useRecoilState(selectContractsState);

    /**
     * Assigned contract
     */
    const [assignedContract, setContract] = useState<string>("");

    /**
     * Update step validation
     */
    const updateStepValidation = useUpdateStep(location, flowState);

    /**
     * Selected claims
     */
    const [selectedClaims, updateClaims] = useRecoilState(selectClaimsState);

    /**
     * Effect to map selected contracts
     */
    useEffect(() => {
        if (isArrayWithContent(selectedContracts)) {
            const mappedClaims = selectedContracts.map(item => {
                const existingClaim = selectedClaims.find(
                    claim => claim.contractId === item.id,
                );
                if (existingClaim) {
                    return existingClaim;
                }
                return {
                    contractId: item.id,
                    reference: item.reference,
                    claims: [],
                    priceCorrectionClaims: [],
                };
            });

            updateClaims(mappedClaims);
        }
    }, [selectedContracts]);

    /**
     * Select claims handler
     */
    const handleSelectClaims = ({ claims, priceCorrectionClaims }) => {
        if (assignedContract) {
            const index = selectedClaims.findIndex(
                item => item.contractId === assignedContract,
            );
            if (index !== -1) {
                const clonedClaims = cloneDeep(selectedClaims);
                clonedClaims[index] = {
                    ...selectedClaims[index],
                    claims,
                    priceCorrectionClaims,
                };

                updateClaims(clonedClaims);
                setContract("");
            }
        }
    };

    /**
     * pass the state back to the layout / higher order component
     */
    useImperativeHandle(ref, () => ({
        onSubmit() {
            return selectedClaims;
        },
    }));

    /**
     * Check if the current step is valid
     * then set isPrepared to true, otherwise set it false
     */
    useEffect(() => {
        const canSaveState =
            !!isArrayWithContent(selectedContracts) &&
            !selectedClaims.some(item => {
                return (
                    item.claims?.length === 0 &&
                    item.priceCorrectionClaims?.length === 0
                );
            });

        updateStepValidation(canSaveState);
    }, [selectedClaims, selectedContracts]);

    /**
     * Map selected claims
     */
    const mapSelectedClaims = useMemo(() => {
        const findContract = selectedClaims.find(
            item => item.contractId === assignedContract,
        );

        return {
            claims: findContract?.claims || [],
            priceCorrectionClaims: findContract?.priceCorrectionClaims || [],
        };
    }, [assignedContract, selectedClaims]);

    /**
     * Delete contract
     * @param contractId
     */
    const deleteContract = (contractId: string) => {
        const clonedClaims = cloneDeep(selectedClaims);
        const filteredClaims = clonedClaims.filter(
            item => item.contractId !== contractId,
        );
        updateClaims(filteredClaims);

        const filteredContracts = selectedContracts.filter(
            item => item.id !== contractId,
        );
        updateContracts(filteredContracts);
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <Typography variant="h2" mb={1}>
                Select claims
            </Typography>

            <AssignClaimsModal
                contractId={assignedContract}
                title="Assign Claims"
                onSave={handleSelectClaims}
                onClose={() => {
                    setContract("");
                }}
                selectedClaims={mapSelectedClaims}
            />

            {selectedClaims?.map(item => (
                <GrayBox
                    id={`${item.contractId}-gray-box`}
                    key={item.contractId}
                    primaryAction={{
                        text:
                            item?.claims?.length === 0 &&
                            item?.priceCorrectionClaims?.length === 0
                                ? "Add"
                                : "Edit",
                        onClick: () => {
                            setContract(item?.contractId);
                        },
                    }}
                    marginY={2}
                    secondaryAction={{
                        text: "Delete",
                        onClick: () => {
                            deleteContract(item?.contractId);
                        },
                    }}
                    header={
                        <Typography variant="h3">{item.reference}</Typography>
                    }
                >
                    <Box display="flex" flexDirection="column">
                        <Typography
                            variant="captionSmall"
                            color={
                                item?.claims?.length === 0 &&
                                item?.priceCorrectionClaims?.length === 0
                                    ? "error"
                                    : "primary"
                            }
                        >
                            {`${item?.claims?.length === 0 ? "There are no claims assigned to this contract" : `There ${item?.claims?.length + item?.priceCorrectionClaims?.length === 1 ? "is" : "are"} ${item?.claims?.length + item?.priceCorrectionClaims?.length} claim${item?.claims?.length + item?.priceCorrectionClaims?.length === 1 ? "" : "s"} assigned to this contract`}`}
                        </Typography>
                    </Box>
                </GrayBox>
            ))}
        </Fragment>
    );
});
export default React.memo(SelectClaims);
