export const PERMISSIONS = {
    // Contracts
    // can read contracts linked to linked to its Contract Team
    hasPermissionToCreateContract: "RightId01",

    // is allowed to edit existing contracts linked to its Contract Team who are not reviewed yet
    hasPermissionToEditContract: "RightId02",

    // can read contracts linked to linked to its Contract Team
    hasPermissionToReadContract: "RightId03",

    // can view and interact with Contract Review Tasks that lead to the review of Contracts
    hasPermissionToReviewContract: "RightId05",

    // Orders
    //can create orders starting from an existing Contract linked to its Contract team. This to allow manual creation of Orders if the automation fails.
    hasPermissionToCreateOrder: "RightId06",

    // can edit existing orders linked to Contracts of her Contract Team
    hasPermissionToEditOrder: "RightId07",

    // can read the list of orders
    hasPermissionToReadOrder: "RightId08",

    //Outcome
    //RightId10 means that the user can create and edit the outcome results linked to the milestones of orders linked to Contracts of her Contract Team. Manually editing outcome results on orders is need if automation of processing outcomes is not in place or down
    hasPermissionToEditOutcome: "RightId10",

    //Financial
    //can read all the Financial Actions created from orders linked to Contracts of her Contract Team. This includes credit note request amounts.
    hasPermissionToReadFinancialActions: "RightId12",

    //can create and edit all the Financial Actions created from orders linked to Contracts of her Contract Team. This includes credit note request amounts.
    hasPermissionToEditFinancialActions: "RightId14",

    //can approve all the Financial Actions created from orders linked to Contracts of her Contract Team. A credit note request is only sent to SAP if it is fully approved in SCP
    hasPermissionToApproveFinancialAction: "RightId15",

    //can confirm the processing or cancellation of a BPF as a user from the finance department
    hasPermissionToApproveBPF: "RightId21",

    // Data table
    //RightId16 means that the user can create and edit new entries in all of the data tables like Accounts, Product, TherapeuticArea, Indication, Brand, ect. Users with this right do not need to be part of a ContractTeam to be able to make ContractTeams!

    // we split it up to CRUD for the consistency and the future changes
    hasPermissionToEditDataTables: "RightId16",

    //users with this right receive Tasks to create and edit Camunda Rules Tables for Contracts. Can also link Camunda Rules tables to Contracts in SCP. Users with this right do not need to be part of a ContractTeam to be able to make ContractTeams!
    hasPermissionToEditCamundaConfiguration: "RightId17",
    // Claims
    //users with this right can read all claims and download the claim reports
    hasPermissionToReadClaim: "RightId18",
    //users with this right can can edit and create claims
    hasPermissionToEditClaim: "RightId19",
};
