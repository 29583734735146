// Lib
import { Box, Typography } from "@mui/material";

import React, { Fragment, useEffect, useState } from "react";

// Own components
import { Table, SearchBar, Modal } from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Hooks
import { useFinance } from "@hooks";

// Types
import { SearchFilters } from "@types";

// Utils
import { apiResponseCounter, constructQueryString } from "@utils";

/**
 * Props type
 */
interface Props {
    disabled?: boolean;
    contractId: string;
    title: string;
    onSave: (claims: {
        claims: string[];
        priceCorrectionClaims: string[];
    }) => void;
    onClose: () => void;
    selectedClaims: {
        claims: string[];
        priceCorrectionClaims: string[];
    };
}

/**
 * Assign Claims Modal
 */
const AssignClaimsModal = ({
    disabled,
    contractId,
    title,
    onSave,
    onClose,
    selectedClaims,
}: Props) => {
    /**
     * Selected contracts
     */
    const [claims, selectClaims] = useState<{
        claims: string[];
        priceCorrectionClaims: string[];
    }>(
        selectedClaims || {
            claims: [],
            priceCorrectionClaims: [],
        },
    );

    // Get claims
    const {
        list: claimList,
        loading: { fetching: claimsLoading },
        search,
    } = useFinance("bpf", "claim|claims", "claims");

    // Get price correction claims
    const {
        list: priceCorrectionClaimsList,
        loading: { fetching: priceCorrectionClaimsLoading },
        search: searchPriceCorrectionClaims,
    } = useFinance("bpf", "claim|claims", "claims");

    /**
     * Select claims handler
     */
    const handleSelectClaims = (
        claimHeaderId: string,
        type: "claims" | "priceCorrectionClaims",
    ) => {
        if (!contractId) return;

        const storedClaims = claims[type];

        const index = storedClaims?.findIndex(
            storedClaimHeaderId => storedClaimHeaderId === claimHeaderId,
        );

        const clonedClaims = storedClaims ? [...storedClaims] : [];

        if (index !== undefined && index > -1) {
            const updatedItems = clonedClaims.filter((_, idx) => index !== idx);
            selectClaims({
                ...claims,
                [type]: updatedItems,
            });
        } else {
            clonedClaims.push(claimHeaderId);
            selectClaims({
                ...claims,
                [type]: clonedClaims,
            });
        }
    };

    useEffect(() => {
        if (selectedClaims) {
            selectClaims(selectedClaims);
        }
    }, [selectedClaims]);

    /**
     * Handle search
     */
    const onSearch = (filters: SearchFilters, isPriceCorrection: boolean) => {
        const hasFilters = Object.values(filters).some(filter => filter.length);

        const params = constructQueryString(filters);

        if (hasFilters) {
            isPriceCorrection
                ? searchPriceCorrectionClaims(
                      `?contractId=${contractId}${params}&priceCorrectionStatus=OPEN`,
                  )
                : search(`?contractId=${contractId}${params}`);
        } else
            isPriceCorrection
                ? searchPriceCorrectionClaims(
                      `?contractId=${contractId}&priceCorrectionStatus=OPEN`,
                  )
                : search(`?contractId=${contractId}`);
    };

    /**
     * Render
     */
    return (
        <Modal
            largeView
            id={`${contractId}-modal`}
            open={!!contractId}
            smallView
            onClose={() => {
                onClose();
                selectClaims({
                    claims: [],
                    priceCorrectionClaims: [],
                });
            }}
            title={title}
            primaryButton={{
                action: () => onSave(claims),
                text: "Save",
                loading: claimsLoading || priceCorrectionClaimsLoading,
                disabled: disabled,
                id: `${contractId}-primary-btn`,
            }}
            secondaryButton={{
                action: () => {
                    onClose();
                },
                text: "Cancel",
                id: `${contractId}-secondary-btn`,
            }}
        >
            <Fragment>
                <Box mb={3}>
                    <Box mb={4}>
                        <Typography variant="h3">Claims</Typography>
                    </Box>

                    <SearchBar
                        id="dashboard-claims"
                        handleSearch={(filters: SearchFilters) =>
                            onSearch(filters, false)
                        }
                        placeholder="Search by supplier or filename..."
                        startDate={false}
                        endDate={false}
                        searchTextOnly
                        simpleLayout
                        /*      searchCallback={() =>
                                updateClaims({
                                    ...selectedClaims,
                                    [contractId]: {
                                        ...selectedClaims[contractId],
                                        claims: [],
                                    },
                                })
                            } */
                    />

                    <Box display="flex" alignItems="baseline" mb={4}>
                        <Typography variant="h3">Search results</Typography>

                        <Typography ml={1} variant="caption1">
                            {apiResponseCounter(
                                claimList,
                                claimsLoading,
                                "claim|claims",
                            )}
                        </Typography>
                    </Box>

                    <Table
                        id={`generate-bpf-claim-list`}
                        headers={HEADERS.BPF_CLAIMS}
                        rows={claimList?.data?.records}
                        loading={claimsLoading}
                        type={ROWRENDERERCONST.BPF_CLAIMS}
                        disabled={disabled}
                        callbacks={{
                            onSelect: (claimHeaderId: string) =>
                                handleSelectClaims(claimHeaderId, "claims"),
                            selectedItems: claims["claims"],
                        }}
                        emptyMsg="No data"
                        maxHeight="38rem"
                        isOverview={false}
                    />
                    <Box mt={1}>
                        <Typography variant="caption1" color="primary">
                            {`${!!claims && claims["claims"]?.length} ${
                                claims && claims["claims"]?.length > 1
                                    ? "claims"
                                    : "claim"
                            } selected`}
                        </Typography>
                    </Box>
                </Box>

                <Box mt={8}>
                    <Box mb={4}>
                        <Typography variant="h3">
                            Price correction claims
                        </Typography>
                    </Box>

                    <SearchBar
                        id="dashboard-claims"
                        handleSearch={(filters: SearchFilters) =>
                            onSearch(filters, true)
                        }
                        placeholder="Search by supplier or filename..."
                        startDate={false}
                        endDate={false}
                        searchTextOnly
                        simpleLayout
                        /*  searchCallback={() =>
                            selectClaims({
                                ...claims,
                                priceCorrectionClaims: [],
                            })
                        } // reset on search change */
                    />

                    <Box display="flex" alignItems="baseline" mb={4}>
                        <Typography variant="h3">Search results</Typography>

                        <Typography ml={1} variant="caption1">
                            {apiResponseCounter(
                                priceCorrectionClaimsList,
                                priceCorrectionClaimsLoading,
                                "price correction claim|price correction claims",
                            )}
                        </Typography>
                    </Box>

                    <Table
                        id={`generate-bpf-claim-list`}
                        headers={HEADERS.BPF_PRICE_CORRECTION_CLAIMS}
                        rows={priceCorrectionClaimsList?.data?.records}
                        loading={priceCorrectionClaimsLoading}
                        type={ROWRENDERERCONST.BPF_PRICE_CORRECTION_CLAIMS}
                        disabled={disabled}
                        callbacks={{
                            onSelect: (claimHeaderId: string) =>
                                handleSelectClaims(
                                    claimHeaderId,
                                    "priceCorrectionClaims",
                                ),
                            selectedItems: claims["priceCorrectionClaims"],
                        }}
                        emptyMsg="No data"
                        maxHeight="38rem"
                        isOverview={false}
                    />
                    <Box mt={1}>
                        <Typography variant="caption1" color="primary">
                            {`${claims["priceCorrectionClaims"]?.length} ${
                                claims &&
                                claims["priceCorrectionClaims"]?.length > 1
                                    ? "Price correction claims"
                                    : "Price correction claim"
                            } selected`}
                        </Typography>
                    </Box>
                </Box>
            </Fragment>
        </Modal>
    );
};

export default React.memo(AssignClaimsModal);
