// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";

//Lib
import {
    Checkbox,
    InputAdornment,
    ListItemText,
    MenuItem,
    Select,
    Typography,
    CircularProgress,
    Box,
} from "@mui/material";
import classnames from "classnames";
import React, { useMemo } from "react";

// Utils
import { isArrayWithContent } from "@utils";

// Style
import * as style from "../style.module.scss";

/**
 * Props type
 */
interface Props {
    id: string;
    renderValue: string;
    loading?: boolean;
    handleFilterChange: any;
    isItemChecked: (
        filter: string,
        value: Record<string, string>,
        key: string,
    ) => boolean;
    labelKey: string;
    valueKey: string;
    data?: Array<any>;
    className?: string | undefined;
    filterName: string;
    disableItemSelection?: boolean;
    fullWidth?: boolean;
    selectedCounter?: (key: string) => number | undefined;
    onClear?: () => void;
}

/**s
 * Select Filter
 */
const SelectFilter: React.FC<Props> = ({
    id,
    renderValue,
    loading,
    handleFilterChange,
    isItemChecked,
    labelKey,
    valueKey,
    data,
    filterName,
    disableItemSelection,
    fullWidth,
    selectedCounter,
    onClear,
}) => {
    const contructRenderValue = useMemo(() => {
        if (!selectedCounter || !selectedCounter(filterName))
            return renderValue;

        return (
            <Box display="flex" alignItems={"center"}>
                {renderValue}
                <Typography
                    className={style.filterCounter}
                    variant="button"
                    color="primary"
                    ml={2}
                    mr={1}
                >
                    {selectedCounter(filterName)}
                </Typography>
                <Typography variant="button" color="primary">
                    {"Selected"}
                </Typography>
            </Box>
        );
    }, [filterName, renderValue, selectedCounter]);

    return (
        <Select
            id={`${id}-filter`}
            value={[]}
            multiple
            fullWidth={fullWidth}
            MenuProps={{
                classes: {
                    paper: style.scrollMenu,
                },
            }}
            className={classnames(style.filterColor, {
                [style.hasSelectedItems]:
                    selectedCounter && selectedCounter(filterName),
            })}
            sx={{ paddingRight: "1rem" }}
            size="small"
            displayEmpty
            renderValue={() => contructRenderValue}
            disabled={loading}
            IconComponent={props =>
                loading ? (
                    <InputAdornment position="start">
                        <CircularProgress color="inherit" size={20} />
                    </InputAdornment>
                ) : (
                    <Box
                        sx={
                            onClear
                                ? {
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4px",
                                      position: "relative",
                                      right: 8,
                                      pointerEvents: "none",
                                      paddingRight: "2rem",
                                  }
                                : undefined
                        }
                    >
                        <KeyboardArrowDownIcon
                            {...props}
                            color="primary"
                            fontSize="small"
                            sx={{ pointerEvents: "none" }}
                        />
                        {!!onClear && (
                            <CloseIcon
                                onClick={e => {
                                    e.stopPropagation();
                                    onClear?.();
                                }}
                                sx={{
                                    position: "absolute",
                                    right: -15,
                                    cursor: "pointer",
                                    pointerEvents: "auto",
                                    fontSize: "20px",
                                    "&:hover": {
                                        color: "rgba(0, 0, 0, 0.7)",
                                    },
                                }}
                            />
                        )}
                    </Box>
                )
            }
            onChange={event => {
                handleFilterChange(filterName, event.target.value, valueKey);
            }}
        >
            {isArrayWithContent(data) ? (
                data?.map((item, index) => (
                    <MenuItem
                        id={`${id}-filter-item-${item[valueKey]}`}
                        key={`${id}-filter-item-key-${index}`}
                        value={item}
                        disabled={disableItemSelection}
                        data-testid={`${id}-filter-option-${item[valueKey]}`}
                    >
                        <Checkbox
                            checked={
                                !!isItemChecked(filterName, item, valueKey)
                            }
                            size="small"
                        />

                        <ListItemText>
                            <Typography variant="caption1">
                                {item[labelKey]}
                            </Typography>
                        </ListItemText>
                    </MenuItem>
                ))
            ) : (
                <MenuItem value={undefined}>
                    <ListItemText>
                        <Typography variant="caption1">No data</Typography>
                    </ListItemText>
                </MenuItem>
            )}
        </Select>
    );
};

export default React.memo(SelectFilter);
