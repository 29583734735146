//Icons
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityIcon from "@mui/icons-material/Visibility";

//Lib
import classnames from "classnames";
import {
    Box,
    Button,
    Checkbox,
    InputAdornment,
    MenuItem,
    TextField,
    Typography,
    Menu,
    Drawer,
} from "@mui/material";
import clonedeep from "lodash.clonedeep";
import { useRecoilState } from "recoil";
import debounce from "lodash.debounce";
import React, {
    Fragment,
    useEffect,
    useRef,
    useState,
    useImperativeHandle,
    useLayoutEffect,
} from "react";

// Types
import { SearchFilters, ViewingOption, Account } from "@types";

// types
import { AccountType, Brand, Country } from "types/api";

// Utils
import { isArrayWithContent } from "@utils";

// Own components
import { ButtonsMenu } from "@components";
import Filters from "./Filters";

// Style
import * as style from "./style.module.scss";

// Atoms
import { paginationState } from "@atoms";
import TaskToggler from "./GroupFilters";

/**
 * Action type
 */
interface Action {
    label?: string;
    callback?: (event?: React.BaseSyntheticEvent, key?: string) => void;
    hidden?: boolean;
    disabled?: boolean;
    type?: "single" | "menu";
    items?: Array<{ key: string; label: string; hidden?: boolean }>;
}

/**
 * Props type
 */
interface Props {
    id: string;
    action?: Action;
    handleSearch?: (filters: SearchFilters, page?: number) => void;
    accountTypes?: { loading: boolean; data: Array<AccountType> };
    therapeuticAreas?: { loading: boolean; data: Array<any> };
    partners?: {
        loading: boolean;
        data: Array<Account>;
        onSearch: (query: string) => void;
    };
    brands?: { loading: boolean; data: Array<Brand> };
    countries?: { loading: boolean; data: Array<Country> };
    products?: { loading: boolean; data: Array<any> };
    taskDefinitionKeys?: {
        loading: boolean;
        data: Array<string>;
    };
    status?: { loading: boolean; data: Array<any> };
    startDate?: boolean;
    endDate?: boolean;
    placeholder?: string;
    simpleLayout?: boolean;
    viewingOptions?: Array<ViewingOption>;
    setViewingOptions?: any;
    priceRange?: boolean;
    hasFilters?: boolean;
    searchTextOnly?: boolean;
    searchCallback?: () => void;
    dateRange?: boolean;
    contractType?: boolean;
    filterKey?: string;
    financeType?: {
        loading: boolean;
        data: Array<{ value: string; label: string }>;
    };
    showTasksTypeFilter?: boolean;
    disableSelectionFilters?: boolean;
    showFinancialTriggerTypeFilter?: boolean;
    hasPagination?: boolean;
}

/**
 * Search bar
 */
const SearchBar = React.forwardRef(
    (
        {
            id,
            action,
            handleSearch,
            accountTypes,
            therapeuticAreas,
            brands,
            countries,
            products,
            partners,
            status,
            startDate = true,
            endDate = true,
            placeholder,
            simpleLayout,
            viewingOptions,
            setViewingOptions,
            priceRange,
            hasFilters = true,
            searchTextOnly = false,
            searchCallback,
            taskDefinitionKeys,
            dateRange,
            contractType,
            filterKey,
            financeType,
            showTasksTypeFilter,
            disableSelectionFilters,
            showFinancialTriggerTypeFilter,
            hasPagination,
        }: Props,
        ref,
    ) => {
        const initialFilterValues = {
            account: [],
            accountType: [],
            product: [],
            brand: [],
            therapeutic_area: [],
            valid_from: "",
            valid_to: "",
            startDate: "",
            endDate: "",
            status: [],
            countryIsoCode: [],
            priceRangeEnd: "",
            priceRangeStart: "",
            taskDefinitionKey: [],
            type: [],
            financeType: [],
            allTasks: showTasksTypeFilter
                ? [
                      {
                          label: "My tasks",
                          value: "false",
                          filterKey: "value",
                      },
                  ]
                : [],
            financialTriggerType: showFinancialTriggerTypeFilter
                ? [
                      {
                          label: "Rebate at infusion",
                          value: "rebateAtInfusion",
                          filterKey: "value",
                      },
                  ]
                : [],
        };

        /**
         * States
         */
        const [showFilters, setShowFilters] = useState<boolean>(false);
        const [searchValue, setSearchValue] = useState("");
        const [filters, setFilters] = useState(initialFilterValues);
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const [pagination, setPagination] = useRecoilState(paginationState);

        /**
         * Debounce filter functionality
         */
        const onSearch = useRef(
            debounce(
                (filterQuery: SearchFilters) =>
                    handleSearch && handleSearch(filterQuery),
                350,
            ),
        ).current;

        useLayoutEffect(() => {
            if (!hasFilters) return;

            if (!!pagination.page && pagination.page > 1) {
                setPagination(prev => ({ ...prev, page: 1 }));
            }

            let unmounted = false;

            if (!unmounted) {
                onSearch({ ...filters, search: searchValue });
            }

            return () => {
                unmounted = true;
            };
        }, [searchValue, filters]);

        useEffect(() => {
            if (!hasFilters || !pagination.page) return;

            onSearch({ ...filters, search: searchValue });
        }, [pagination.page]);

        useLayoutEffect(() => {
            if (!hasPagination || !filterKey) return;

            setPagination({ type: filterKey, page: 1 });
        }, []);

        /**
         * Delete all filters
         */
        const onDeleteAllFilters = () => {
            setFilters(initialFilterValues);
        };

        /**
         * Call search with filters from HOC
         */
        useImperativeHandle(ref, () => ({
            onSearchReset() {
                onDeleteAllFilters();
                setSearchValue("");
                if (hasPagination) {
                    setPagination(prev => ({ ...prev, page: 1 }));
                }
            },
        }));

        useEffect(() => {
            if (!filterKey) return;

            if (typeof window !== "undefined") {
                const storedFilters = sessionStorage.getItem(
                    `FILTER_${filterKey}`,
                );

                const storedSearchValue = sessionStorage.getItem(
                    `SEARCH_VALUE_${filterKey}`,
                );

                if (storedFilters) {
                    setFilters(JSON.parse(storedFilters));
                }

                if (storedSearchValue) {
                    setSearchValue(JSON.parse(storedSearchValue));
                }
            }
        }, [filterKey]);

        useEffect(() => {
            if (!filterKey) return;
            if (typeof window !== "undefined") {
                sessionStorage.setItem(
                    `FILTER_${filterKey}`,
                    JSON.stringify(filters),
                );
            }
        }, [filters, filterKey, searchValue]);

        useEffect(() => {
            if (!filterKey) return;
            if (typeof window !== "undefined") {
                sessionStorage.setItem(
                    `SEARCH_VALUE_${filterKey}`,
                    JSON.stringify(searchValue),
                );
            }
        }, [filterKey, searchValue]);

        const handleFreeTextSearch = (value: string) => {
            setSearchValue(value);

            if (searchCallback) {
                searchCallback();
            }
        };

        const selectedCount = (
            filterSource: Record<string, any>,
            key: string,
        ) => {
            return filterSource[key].length > 0
                ? filterSource[key].length
                : undefined;
        };

        /**
         * Render
         */
        return (
            <div
                className={classnames(style.wrapper, {
                    [style.elevatedSearch]: !simpleLayout,
                })}
            >
                <Box>
                    <div
                        className={classnames(style.searchBox, {
                            [style.withoutSearch]:
                                !hasFilters && !action?.hidden,
                        })}
                    >
                        {!!action &&
                            !action.hidden &&
                            (action.type === "menu" &&
                            !!action.items &&
                            isArrayWithContent(action.items) ? (
                                <ButtonsMenu
                                    id={`${id}-search-btn-menu`}
                                    startIcon={<AddIcon />}
                                    disabled={action.disabled}
                                    items={action.items}
                                    label={action?.label || ""}
                                    onSelect={(
                                        event: React.BaseSyntheticEvent,
                                        key: string,
                                    ) =>
                                        action?.callback
                                            ? action?.callback(event, key)
                                            : undefined
                                    }
                                />
                            ) : (
                                <Button
                                    variant="contained"
                                    disableElevation
                                    startIcon={<AddIcon />}
                                    color="primary"
                                    disabled={action.disabled}
                                    onClick={action.callback}
                                    id={`${id}-search-btn`}
                                >
                                    <Typography variant="button">
                                        {action.label}
                                    </Typography>
                                </Button>
                            ))}

                        {hasFilters && (
                            <TextField
                                size="small"
                                className={style.searchField}
                                id={`${id}-search-field`}
                                autoComplete="off"
                                placeholder={placeholder}
                                value={searchValue}
                                onChange={event =>
                                    handleFreeTextSearch(event.target.value)
                                }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                        {showTasksTypeFilter && (
                            <TaskToggler
                                id={`${id}-task-toggler`}
                                value={filters.allTasks[0]}
                                onChange={value => {
                                    const copyFilters = clonedeep(filters);
                                    copyFilters["allTasks"] = [{ ...value }];
                                    setFilters(copyFilters);
                                }}
                                options={[
                                    {
                                        label: "My tasks",
                                        value: "false",
                                        filterKey: "value",
                                    },
                                    {
                                        label: "All tasks",
                                        value: "true",
                                        filterKey: "value",
                                    },
                                ]}
                            />
                        )}

                        {showFinancialTriggerTypeFilter && (
                            <TaskToggler
                                id={`${id}-task-toggler`}
                                value={filters.financialTriggerType[0]}
                                onChange={value => {
                                    const copyFilters = clonedeep(filters);
                                    copyFilters["financialTriggerType"] = [
                                        { ...value },
                                    ];
                                    setFilters(copyFilters);
                                }}
                                options={[
                                    {
                                        label: "Rebate at infusion",
                                        value: "rebateAtInfusion",
                                        filterKey: "value",
                                    },
                                    {
                                        label: "Rebate at apheresis",
                                        value: "rebateAtApheresis",
                                        filterKey: "value",
                                    },
                                ]}
                            />
                        )}

                        {!!setViewingOptions && (
                            <Fragment>
                                <Button
                                    id={`${id}-viewing-options-list`}
                                    aria-controls={
                                        anchorEl
                                            ? "table-view-options"
                                            : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                        anchorEl ? "true" : undefined
                                    }
                                    variant="outlined"
                                    color="primary"
                                    onClick={(
                                        event: React.MouseEvent<HTMLElement>,
                                    ) => setAnchorEl(event.currentTarget)}
                                    endIcon={
                                        anchorEl ? (
                                            <KeyboardArrowUpIcon />
                                        ) : (
                                            <KeyboardArrowDownIcon />
                                        )
                                    }
                                    startIcon={<VisibilityIcon />}
                                >
                                    <Typography variant="button">
                                        Viewing options
                                    </Typography>
                                </Button>

                                <Menu
                                    id="table-view-options"
                                    anchorEl={anchorEl}
                                    open={!!anchorEl}
                                    onClose={() => {
                                        setAnchorEl(null);
                                    }}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                >
                                    {isArrayWithContent(viewingOptions) &&
                                        viewingOptions!.map(
                                            (option, index) =>
                                                !!option.rowKey && (
                                                    <MenuItem
                                                        key={`${option?.colIdx}-column`}
                                                        value={option.label}
                                                        onClick={() =>
                                                            setViewingOptions(
                                                                option,
                                                                index,
                                                            )
                                                        }
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                option.checked
                                                            }
                                                            size="small"
                                                        />
                                                        <Typography
                                                            variant="caption1"
                                                            sx={{
                                                                color: "text.secondary",
                                                            }}
                                                        >
                                                            {option?.label}
                                                        </Typography>
                                                    </MenuItem>
                                                ),
                                        )}
                                </Menu>
                            </Fragment>
                        )}
                        {hasFilters && !searchTextOnly && (
                            <Button
                                id={`${id}-show-filters-toggler`}
                                variant="outlined"
                                startIcon={<TuneIcon />}
                                color="primary"
                                onClick={() => setShowFilters(true)}
                            >
                                <Typography variant="button">
                                    Filters
                                </Typography>
                            </Button>
                        )}
                    </div>
                    <div>
                        <Drawer
                            open={showFilters}
                            anchor="right"
                            onClose={() => setShowFilters(false)}
                            className={style.drawer}
                        >
                            <div id={`${id}-filters-wrapper`}>
                                <Filters
                                    id={`${id}-filters`}
                                    setFilters={setFilters}
                                    setShowFilters={setShowFilters}
                                    searchCallback={searchCallback}
                                    dateRange={dateRange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    products={products}
                                    countries={countries}
                                    taskDefinitionKeys={taskDefinitionKeys}
                                    disableSelectionFilters={
                                        disableSelectionFilters
                                    }
                                    accountTypes={accountTypes}
                                    contractType={contractType}
                                    filters={filters}
                                    status={status}
                                    financeType={financeType}
                                    brands={brands}
                                    therapeuticAreas={therapeuticAreas}
                                    partners={partners}
                                    priceRange={priceRange}
                                    // showTasksTypeFilter={showTasksTypeFilter}
                                    // showFinancialTriggerTypeFilter={
                                    //   showFinancialTriggerTypeFilter
                                    // }
                                    selectedCount={selectedCount}
                                    initialFilters={initialFilterValues}
                                />
                            </div>
                        </Drawer>
                        <div className={style.selectedFiltersWrapper}>
                            <Filters
                                id={`${id}-filters`}
                                filters={filters}
                                setFilters={setFilters}
                                setShowFilters={setShowFilters}
                                searchCallback={searchCallback}
                                dateRange={dateRange}
                                startDate={startDate}
                                endDate={endDate}
                                products={products}
                                countries={countries}
                                taskDefinitionKeys={taskDefinitionKeys}
                                disableSelectionFilters={
                                    disableSelectionFilters
                                }
                                accountTypes={accountTypes}
                                contractType={contractType}
                                status={status}
                                financeType={financeType}
                                brands={brands}
                                therapeuticAreas={therapeuticAreas}
                                partners={partners}
                                priceRange={priceRange}
                                // showTasksTypeFilter={showTasksTypeFilter}
                                //  showFinancialTriggerTypeFilter={
                                //     showFinancialTriggerTypeFilter
                                // }
                                selectedCount={selectedCount}
                                hasSelectedFilters={(
                                    filterName: string,
                                    isString?: boolean,
                                ) => {
                                    if (isString) return !!filters[filterName];
                                    return isArrayWithContent(
                                        filters[filterName],
                                    );
                                }}
                                isFiltersOverview={true}
                            />

                            <div>
                                {!isArrayWithContent(filters.allTasks) &&
                                    !isArrayWithContent(
                                        filters?.financialTriggerType,
                                    ) &&
                                    Object.values(filters).some(
                                        filter => filter.length,
                                    ) && (
                                        <Button
                                            id={`${id}-remove-all-filters-btn`}
                                            variant="text"
                                            className={style.removeFilters}
                                            onClick={() => onDeleteAllFilters()}
                                        >
                                            Reset filters
                                        </Button>
                                    )}
                            </div>
                        </div>
                    </div>
                </Box>
            </div>
        );
    },
);

export default SearchBar;
