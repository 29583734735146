import { useState, useCallback, useEffect } from "react";
import { RecoilState, useResetRecoilState } from "recoil";

/**
 * Hook to check if the flow is in progress
 */
interface UseFlowCheckReturn {
    isFlowInProgress: boolean;
    setFlowInProgress: () => void;
    resetFlow: () => void;
}

export const useFlowCheck = (): UseFlowCheckReturn => {
    /**
     * Functions
     */
    const resetFlow = useCallback(() => {
        sessionStorage.setItem("isFlowInProgress", "false");
    }, []);

    /**
     * Set flow in progress
     */
    const setFlowInProgress = useCallback(() => {
        sessionStorage.setItem("isFlowInProgress", "true");
    }, []);

    /**
     * Return
     */
    return {
        isFlowInProgress: sessionStorage.getItem("isFlowInProgress") === "true",
        setFlowInProgress,
        resetFlow,
    };
};

interface UseResetReturn {
    resetKey: number;
    reset: () => void;
    isFlowInProgress: boolean;
    initializeFlow: () => void;
}

/**
 * Hook to manage resets using a single selector
 * @param selector - A Recoil selector that manages multiple atom resets
 */
export const useAtomsReset = (
    selector: RecoilState<any>,
    canInitializeFlow?: boolean,
): UseResetReturn => {
    /**
     * Hooks
     */
    const { isFlowInProgress, setFlowInProgress, resetFlow } = useFlowCheck();

    /**
     * States
     */
    const [resetKey, setResetKey] = useState(0);

    /**
     * Recoil State
     */
    const resetState = useResetRecoilState(selector);

    /**
     * Reset
     */
    const reset = useCallback(() => {
        resetFlow();
        resetState();
        setResetKey(prev => prev + 1);
        setFlowInProgress();
    }, [resetState, resetFlow, setFlowInProgress]);

    /**
     * Functions
     */
    const initializeFlow = useCallback(() => {
        setFlowInProgress();
    }, [setFlowInProgress]);

    useEffect(() => {
        if (!isFlowInProgress) {
            reset();
        }
    }, [isFlowInProgress, reset]);

    /**
     * Effects
     */
    useEffect(() => {
        if (!!isFlowInProgress && canInitializeFlow) {
            initializeFlow();
        }
    }, [canInitializeFlow, initializeFlow, isFlowInProgress]);

    /**
     * Return
     */
    return { resetKey, reset, isFlowInProgress, initializeFlow };
};
