// Libs
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

// Own components
import { ActionMenu } from "@components";

interface Row {
    context: { name: string; id: string; type: string };
    externalRegionName?: string;
    externalAccountName?: string;
    externalRegionCode?: string;
    externalAccountCode?: string;
    externalProductCode?: string;
    externalProductName?: string;
    externalContractName?: string;
    externalContractCode?: string;
    type: string;
}

/**
 * Props type
 */
interface Props {
    row: Row;
    rowIndex: number;
    callbacks: any;
    id: string;
}

/**
 * ExternalCodes
 */
const ExternalCodes = ({ id, row, rowIndex, callbacks }: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
        >
            <TableCell>
                <Typography variant="caption2">
                    {row?.context?.name || "-"}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.externalRegionCode ||
                        row.externalAccountCode ||
                        row.externalProductCode ||
                        row.externalContractCode ||
                        "-"}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.externalRegionName ||
                        row.externalAccountName ||
                        row.externalProductName ||
                        row.externalContractName ||
                        "-"}
                </Typography>
            </TableCell>

            <TableCell align="right">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <ActionMenu
                        id={`${id}-row-${rowIndex}-partner`}
                        autoHide
                        options={[
                            {
                                label: "Edit",
                                action: () =>
                                    callbacks &&
                                    callbacks.onEdit(row, rowIndex),
                            },
                        ]}
                        rowIndex={rowIndex}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default ExternalCodes;
