import { atom } from "recoil";

export type ClaimBPFMap = {
    contractId: string;
    reference: string;
    claims: string[];
    priceCorrectionClaims: string[];
};

/**
 * select category state
 */
export const selectClaimsState = atom({
    key: "selectClaimsState",
    default: [] as ClaimBPFMap[],
});
