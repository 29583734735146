// extracted by mini-css-extract-plugin
export var chip = "style-module--chip--81132";
export var clickable = "style-module--clickable--04aa7";
export var error = "#ff3131";
export var item = "style-module--item--81567";
export var lightTitle = "style-module--lightTitle--c211a";
export var primary = "#036";
export var secondary = "#019cdc";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var version = "style-module--version--e23d4";
export var white = "#fff";
export var wrapper = "style-module--wrapper--de271";