import { atom } from "recoil";

type BPFPaymentInformation = {
    invoiceDate: string;
    paymentDueDate: string;
    bpfProcessDate: string;
    bankType: string;
    originalInvoice: File | undefined;
};

/**
 * BPF General Information State
 */
export const BPFPaymentInformationState = atom({
    key: "BPFPaymentInformationState",
    default: {} as BPFPaymentInformation,
});
