// Icons
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// Lib
import {
    Typography,
    InputLabel,
    Grid,
    Box,
    FormHelperText,
    CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { DatePicker } from "@mui/x-date-pickers";
import { useRecoilState, useRecoilValue } from "recoil";

// Hooks
import { useUpdateStep, useBpfPaymentCondition } from "@hooks";

// Atoms
import {
    BPFGeneralInformationState,
    BPFPaymentInformationState,
    selectContractsState,
} from "@atoms";

// Utils
import {
    convertDateForAPI,
    datePlaceholder,
    isArrayWithContent,
    subtractWorkingDays,
} from "@utils";

// Components
import { Dropzone, FormFooter, InfoBox, NumberInput } from "@components";

/**
 * Props type
 */
interface Props {
    flowState?: string;
    location?: Location;
}

/**
 * Payment Information
 */
const BPFPaymentInformation = ({ flowState, location }: Props) => {
    const { load, list: response, loading } = useBpfPaymentCondition();

    /**
     * Recoil
     */
    const selectedContracts = useRecoilValue(selectContractsState);

    // toucheded
    const [touched, setToucheded] = useState<{
        invoiceDate: boolean;
        paymentDueDate: boolean;
        bpfProcessDate: boolean;
        bankType: boolean;
        originalInvoice: boolean;
    }>({
        invoiceDate: false,
        paymentDueDate: false,
        bpfProcessDate: false,
        bankType: false,
        originalInvoice: false,
    });

    // Add new validation state
    const [dateErrors, setDateErrors] = useState({
        bpfProcessDate: false,
        paymentDueDate: false,
    });

    /**
     * BPF General Information State
     */
    const [values, updateValuesState] = useRecoilState(
        BPFPaymentInformationState,
    );

    /**
     * General Information State
     */
    const generalInformation = useRecoilValue(BPFGeneralInformationState);

    /**
     * Update step validation
     */
    const updateStepValidation = useUpdateStep(location, flowState);

    useEffect(() => {
        if (!!values?.paymentDueDate || !isArrayWithContent(selectedContracts))
            return;

        if (values?.invoiceDate) {
            const contractIds = selectedContracts.map(contract => contract.id);

            load(contractIds).then(response => {
                const condition = response?.data?.paymentConditionInDays;

                if (condition) {
                    // Calculate payment due date (invoice date - 6 days)
                    const invoiceDate = DateTime.fromISO(values.invoiceDate);
                    const paymentDueDate = invoiceDate.plus({
                        days: Number(condition),
                    });
                    updateValuesState(prevValues => ({
                        ...prevValues,
                        paymentDueDate: paymentDueDate.toISODate() || "",
                    }));
                }
            });
        }
    }, [selectedContracts, values?.paymentDueDate, values?.invoiceDate]);

    /**
     * Update recoil state
     */
    const onChange = (key: string, value: any) => {
        if (!key) return;

        updateValuesState(prevValues => ({
            ...prevValues,
            [key]: value,
        }));
    };

    useEffect(() => {
        if (
            !!values?.bankType ||
            !generalInformation?.contractPartner?.bankType
        )
            return;

        updateValuesState({
            ...values,
            bankType: generalInformation?.contractPartner?.bankType,
        });
    }, [generalInformation]);

    useEffect(() => {
        if (!!values?.paymentDueDate && !!values?.bpfProcessDate) return;

        // Only calculate bpfProcessDate if both invoiceDate and paymentDueDate exist
        if (!!values.invoiceDate && !!values.paymentDueDate) {
            const processDate = subtractWorkingDays(
                DateTime.fromISO(values.paymentDueDate),
                7,
            );
            updateValuesState(prevValues => ({
                ...prevValues,
                bpfProcessDate: processDate.toISODate() || "",
            }));
        }
    }, [values?.paymentDueDate]);

    /**
     * Check if the current step is valid
     * then set isPrepared to true, otherwise set it false
     */
    useEffect(() => {
        const canSaveState =
            !!values?.invoiceDate &&
            !!values?.paymentDueDate &&
            !!values?.bpfProcessDate &&
            !!values?.bankType &&
            !!values?.originalInvoice &&
            !dateErrors.paymentDueDate &&
            !dateErrors.bpfProcessDate;

        updateStepValidation(canSaveState);
    }, [values, dateErrors]);

    /**
     * Render
     */
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height={1}
        >
            <div>
                <Typography variant="h2" mb={1}>
                    Payment information
                </Typography>
                <Grid container mt={3} mb={7} spacing={3}>
                    <Grid item xs={6}>
                        <InputLabel
                            id={`invoice-date-label`}
                            shrink
                            error={
                                !values?.invoiceDate && touched["invoiceDate"]
                            }
                        >
                            {"Invoice date (*)"}
                        </InputLabel>

                        <DatePicker
                            value={datePlaceholder(values?.invoiceDate)}
                            onChange={value => {
                                onChange(
                                    "invoiceDate",
                                    !value || !value?.isValid
                                        ? ""
                                        : convertDateForAPI(value),
                                );
                            }}
                            format="dd/MM/yyyy"
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    autoComplete: "off",
                                    sx: {
                                        svg: {
                                            color: "#036",
                                            opacity: "0.5",
                                        },
                                    },
                                    size: "small",
                                    fullWidth: true,
                                    placeholder: "dd/mm/yyyy",
                                    required: true,
                                    error:
                                        !values?.invoiceDate &&
                                        touched["invoiceDate"],
                                    id: `invoice-date-input`,
                                    name: "invoice-date-input",
                                    onBlur: () => {
                                        setToucheded({
                                            ...touched,
                                            invoiceDate: true,
                                        });
                                    },
                                },
                            }}
                            slots={{
                                openPickerIcon: CalendarTodayIcon,
                            }}
                        />
                        {!values?.invoiceDate && touched?.invoiceDate && (
                            <FormHelperText error>
                                This field is required
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel
                            id={`payment-due-date-label`}
                            shrink
                            error={
                                !values?.paymentDueDate &&
                                touched["paymentDueDate"]
                            }
                        >
                            {"Payment due date (*)"}
                        </InputLabel>

                        <DatePicker
                            disabled={!values?.invoiceDate || loading}
                            value={datePlaceholder(values?.paymentDueDate)}
                            onChange={value => {
                                if (!value || !value?.isValid) {
                                    onChange("paymentDueDate", "");
                                    return;
                                }

                                // Validate against invoice date
                                if (
                                    values?.invoiceDate &&
                                    value.startOf("day") <
                                        DateTime.fromISO(
                                            values.invoiceDate,
                                        ).startOf("day")
                                ) {
                                    setDateErrors(prev => ({
                                        ...prev,
                                        paymentDueDate: true,
                                    }));
                                    return;
                                }

                                setDateErrors(prev => ({
                                    ...prev,
                                    paymentDueDate: false,
                                }));
                                onChange(
                                    "paymentDueDate",
                                    convertDateForAPI(value),
                                );
                            }}
                            format="dd/MM/yyyy"
                            loading={loading}
                            minDate={
                                values?.invoiceDate
                                    ? DateTime.fromISO(values.invoiceDate)
                                    : undefined
                            }
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    autoComplete: "off",
                                    sx: {
                                        svg: {
                                            color: "#036",
                                            opacity: "0.5",
                                        },
                                    },
                                    size: "small",
                                    fullWidth: true,
                                    placeholder: "dd/mm/yyyy",
                                    required: true,
                                    error:
                                        !values?.paymentDueDate &&
                                        touched["paymentDueDate"],
                                    id: `payment-due-date-input`,
                                    name: "payment-due-date-input",
                                    disabled: !values?.invoiceDate || loading,
                                    onBlur: () => {
                                        setToucheded({
                                            ...touched,
                                            paymentDueDate: true,
                                        });
                                    },
                                },
                            }}
                            slots={{
                                openPickerIcon: loading
                                    ? () => <CircularProgress size={20} />
                                    : CalendarTodayIcon,
                            }}
                        />
                        {dateErrors.paymentDueDate && (
                            <FormHelperText error>
                                Date must be equal to or after the invoice date
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel
                            id={`bpf-process-date-label`}
                            shrink
                            error={
                                !values?.bpfProcessDate &&
                                touched["bpfProcessDate"]
                            }
                        >
                            {"BPF process date (*)"}
                        </InputLabel>

                        <DatePicker
                            value={datePlaceholder(values?.bpfProcessDate)}
                            disabled={
                                !values?.paymentDueDate ||
                                !values?.invoiceDate ||
                                loading
                            }
                            maxDate={
                                values?.paymentDueDate
                                    ? DateTime.fromISO(values.paymentDueDate)
                                    : undefined
                            }
                            shouldDisableDate={date => {
                                if (!values?.paymentDueDate) return false;
                                return (
                                    date.startOf("day") >
                                    DateTime.fromISO(
                                        values.paymentDueDate,
                                    ).startOf("day")
                                );
                            }}
                            onChange={value => {
                                if (!value || !value?.isValid) {
                                    onChange("bpfProcessDate", "");
                                    return;
                                }

                                // Validate against payment due date
                                if (
                                    values?.paymentDueDate &&
                                    value.startOf("day") >
                                        DateTime.fromISO(
                                            values.paymentDueDate,
                                        ).startOf("day")
                                ) {
                                    setDateErrors(prev => ({
                                        ...prev,
                                        bpfProcessDate: true,
                                    }));
                                    return;
                                }

                                setDateErrors(prev => ({
                                    ...prev,
                                    bpfProcessDate: false,
                                }));
                                onChange(
                                    "bpfProcessDate",
                                    convertDateForAPI(value),
                                );
                            }}
                            format="dd/MM/yyyy"
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    autoComplete: "off",
                                    disabled:
                                        !values?.paymentDueDate ||
                                        !values?.invoiceDate ||
                                        loading,
                                    sx: {
                                        svg: {
                                            color: "#036",
                                            opacity: "0.5",
                                        },
                                    },
                                    size: "small",
                                    fullWidth: true,
                                    placeholder: "dd/mm/yyyy",
                                    required: true,
                                    error:
                                        !values?.bpfProcessDate &&
                                        touched["bpfProcessDate"],
                                    id: `bpf-process-date-input`,
                                    name: "bpf-process-date-input",
                                    onBlur: () => {
                                        setToucheded({
                                            ...touched,
                                            bpfProcessDate: true,
                                        });
                                    },
                                    onKeyDown: e => {
                                        // Prevent manual input
                                        if (
                                            e.key !== "Tab" &&
                                            e.key !== "Backspace" &&
                                            e.key !== "Delete"
                                        ) {
                                            e.preventDefault();
                                        }
                                    },
                                },
                            }}
                            slots={{
                                openPickerIcon: loading
                                    ? () => <CircularProgress size={20} />
                                    : CalendarTodayIcon,
                            }}
                        />
                        {dateErrors.bpfProcessDate && (
                            <FormHelperText error>
                                Date must be equal to or before the payment due
                                date
                            </FormHelperText>
                        )}
                    </Grid>

                    {
                        <Grid item xs={12} md={6}>
                            <InputLabel
                                shrink
                                error={!values?.bankType && touched["bankType"]}
                            >
                                {"Bank type (*)"}
                            </InputLabel>
                            <NumberInput
                                name="bankType"
                                variant="outlined"
                                fullWidth
                                value={values?.bankType}
                                onBlur={() => {
                                    setToucheded({
                                        ...touched,
                                        bankType: true,
                                    });
                                }}
                                id={`bankType`}
                                autoComplete="off"
                                error={!values?.bankType && touched["bankType"]}
                                onChange={e => {
                                    onChange("bankType", e.target.value);
                                }}
                                size="small"
                            />

                            {!!touched?.bankType &&
                                (!values.bankType ||
                                    (!!values?.bankType &&
                                        (Number(values?.bankType) > 10 ||
                                            Number(values?.bankType) < 1))) && (
                                    <FormHelperText error>
                                        {!!values?.bankType &&
                                        (Number(values?.bankType) > 10 ||
                                            Number(values?.bankType) < 1)
                                            ? "Please enter a bank type between 1 and 10"
                                            : " This field is required"}
                                    </FormHelperText>
                                )}
                        </Grid>
                    }

                    <Grid item xs={12} mt={4}>
                        <InputLabel
                            shrink
                            error={
                                !!touched?.originalInvoice &&
                                !values?.originalInvoice
                            }
                        >
                            {"Evidence (*)"}
                        </InputLabel>
                        <Dropzone
                            disabled={!!values?.originalInvoice}
                            variant="big"
                            id={"upload-contract-document"}
                            fileTypes={{
                                "application/pdf": [".pdf"],
                                "image/png": [".png"],
                                "image/jpeg": [".jpeg", ".jpg"],
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                    [".docx"],
                                "application/msword": [".doc"],
                                "application/vnd.ms-excel": [".xls"],
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                    [".xlsx"],
                                "application/vnd.ms-outlook": [".msg"],
                            }}
                            files={[values?.originalInvoice]}
                            onUpload={uploadFiles => {
                                onChange("originalInvoice", uploadFiles);
                            }}
                            onAttachmentRemove={() => {
                                onChange("originalInvoice", undefined);
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            {!!response?.data?.warning && (
                <Box mt="3.125rem">
                    <InfoBox
                        id="warning-for-using-strict-payment-condition"
                        warning
                        message={`${response?.data?.warning}`}
                    />
                </Box>
            )}

            <FormFooter
                id={`generate-bpf-footer`}
                error={
                    (!!touched?.invoiceDate && !values?.invoiceDate) ||
                    (!!touched?.paymentDueDate && !values?.paymentDueDate) ||
                    (!!touched?.bpfProcessDate && !values?.bpfProcessDate)
                }
                textAlign="right"
            />
        </Box>
    );
};

export default React.memo(BPFPaymentInformation);
