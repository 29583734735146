import { Account } from "@types";
import { atom } from "recoil";

type BPFGeneralInformation = {
    bpfName: string;
    paymentReference: string;
    file: File | undefined;
    contractPartner: Account | undefined;
};

/**
 * BPF General Information State
 */
export const BPFGeneralInformationState = atom({
    key: "BPFGeneralInformationState",
    default: {} as BPFGeneralInformation,
});
