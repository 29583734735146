// Lib
import { Box } from "@mui/material";
import { navigate } from "gatsby";
import React, {
    Fragment,
    useEffect,
    useImperativeHandle,
    useMemo,
} from "react";

// Own components
import { SearchBar, Table } from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Hooks
import {
    useMaintenance,
    useGetTeams,
    useViewingOptions,
    useRefreshReportingData,
} from "@hooks";

// Utils
import { isArrayWithContent } from "@utils";
import { ContractTeam } from "@types";

/**
 * Props type
 */
type Props = {
    id?: string;
    onDataReady?: () => void;
};

interface ContractTeamsOverviewRef {
    getRecordsCount: () => {
        itemCount: number | undefined;
    };
}

/**
 * Teams overview
 */
const ContractTeamsOverview = React.forwardRef<ContractTeamsOverviewRef, Props>(
    ({ id, onDataReady }, ref) => {
        /**
         * Hooks
         */
        const { viewingOptions, setViewingOptions } = useViewingOptions(
            ROWRENDERERCONST.CONTRACT_TEAMS,
        );

        /**
         * Refresh Reporting Data
         */
        const {
            refreshReportingData,
            loading: loadingRefreshingReportingData,
        } = useRefreshReportingData();

        /**
         * Get team list
         */
        const { response: teams, loading: loadingTeams } = useGetTeams();

        /**
         * Fetch countries
         */

        const {
            list: countries,
            loading: { listLoading: loadingCountries },
        } = useMaintenance("country", "country|countries");

        /**
         * Therapeutic areas
         */

        const {
            list: therapeuticAreas,
            loading: { listLoading: loadingTherapeuticArea },
        } = useMaintenance(
            "therapeuticArea",
            "therapeuticArea|therapeuticAreas",
        );

        /**
         * Map TherapeuticArea & countries
         */
        const teamsMapper = (field: string, arr: Array<any>) => {
            if (
                !field ||
                !isArrayWithContent(arr) ||
                !isArrayWithContent(teams?.data?.records) ||
                !isArrayWithContent(countries?.maintenanceCountryList) ||
                !isArrayWithContent(
                    therapeuticAreas?.maintenanceTherapeuticAreaList,
                )
            )
                return;

            if (field === "therapeuticAreas") {
                const filteredTherapeuticArea =
                    therapeuticAreas.maintenanceTherapeuticAreaList.filter(
                        element => arr.includes(element.therapeuticAreaId),
                    );
                return filteredTherapeuticArea
                    .map(el => el.therapeuticAreaName)
                    .join(", ");
            }
        };

        /**
         * Reverse table data
         */
        const data = useMemo(() => {
            if (!isArrayWithContent(teams?.data?.records)) return [];
            const reversed = teams.data.records.reverse();
            return reversed;
        }, [teams?.data?.records]);

        /**
         * Data ready
         */
        useEffect(() => {
            if (data && !loadingTeams) {
                onDataReady?.();
            }
        }, [data, loadingTeams]);

        /**
         * Imperative handle
         */
        useImperativeHandle(
            ref,
            () => ({
                getRecordsCount() {
                    return {
                        itemCount: data.itemCount || data?.length,
                    };
                },
            }),
            [data],
        );

        /**
         * Render
         */
        return (
            <Fragment>
                <Box mt={4} display="flex" justifyContent="flex-end">
                    <SearchBar
                        id={`${id}-contract-teams-search`}
                        filterKey={ROWRENDERERCONST.CONTRACT_TEAMS}
                        setViewingOptions={setViewingOptions}
                        viewingOptions={viewingOptions}
                        action={{
                            label: "New team",
                            callback: () =>
                                navigate("/contract-team/create-team/"),
                        }}
                        hasFilters={false}
                    />
                </Box>

                <Table
                    id={`${id}-contract-teams-list`}
                    headers={HEADERS.CONTRACT_TEAMS}
                    rows={data || []}
                    loading={
                        loadingTeams ||
                        loadingCountries ||
                        loadingTherapeuticArea ||
                        loadingRefreshingReportingData
                    }
                    type={ROWRENDERERCONST.CONTRACT_TEAMS}
                    callbacks={{
                        onEditTeam: option =>
                            navigate(
                                `/contract-team/edit-team/${option.teamId}/`,
                            ),
                        onRefreshReportingData: (item: ContractTeam) =>
                            refreshReportingData(
                                item?.countries?.[0]?.isoCode,
                                item?.countries?.[0]?.name,
                            ),
                    }}
                    viewingOptions={viewingOptions}
                    mapper={teamsMapper}
                    emptyMsg
                />
            </Fragment>
        );
    },
);
export default React.memo(ContractTeamsOverview);
