import { SystemInfo } from "@types";
import { atom } from "recoil";

/**
 * System atom
 */
export const systemState = atom<SystemInfo>({
    key: "systemState",
    default: {} as SystemInfo,
});
