import { BasicRegionType } from "types/region";

export enum AccountStatus {
    PENDING = "PENDING",
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export interface Account {
    accountId: string;
    knowyoursupplierid?: string;
    accountTypeId?: string;
    homeCountry?: string;
    accountStatus: AccountStatus;
    accountName: string;
    accountCity?: string;
    accountPostalCode?: string;
    accountAddress?: string;
    bankType?: string;
}

export interface PartnerRoles {
    roleName: string;
    roleId: string;
    description: string;
}

export type Partner = {
    id: string;
    name: string;
    role?: string;
    status?: string;
};

export interface CreateAccountForm {
    accountId: string;
    accountName: string;
    homeCountry: { countryIsoCode: string; country: string };
    countryIsoCode: { countryIsoCode: string; country: string };
    sapAccountCode: string;
    knowyoursupplierid: string;
    accountType: { accountTypeId: string; accountTypeName: string };
    accountCity: string;
    accountAddress: string;
    accountPostalCode: string;
    accountStatus: { label: string; value: string };
    bankType: string | null;
    codsId: string | null;
    trustAccountCode?: Account | null;
    regionCode?: BasicRegionType;
    regionCode1?: BasicRegionType;
    regionCode2?: BasicRegionType;
    cmuRegionCode?: BasicRegionType;
}
