export const BREADCRUMBS_MAP = [
    {
        id: "create-contract",
        label: "New contract",
        homePath: "/dashboard/contracts/",
    },
    {
        id: "invoice-release-details",
        label: "Invoice release details",
        homePath: "/dashboard/finances/invoice-release/",
    },
    {
        id: "credit-note-details",
        label: "Credit notes details",
        homePath: "/dashboard/finances/credit-note-outcome/",
    },
    {
        id: "create-team",
        label: "New contract team",
        homePath: "/maintenance/teams/",
    },
    {
        id: "edit-team",
        label: "Edit contract team",
        homePath: "/maintenance/teams/",
    },
    {
        id: "create-outcome",
        label: "Register a new outcome",
        homePath: "/dashboard/outcomes/",
    },
    {
        id: "create-partner",
        label: "New partner",
        homePath: "/dashboard/partners/",
    },
    {
        id: "edit-partner",
        label: "Edit partner",
        homePath: "/dashboard/partners/",
    },
    {
        id: "view-contract",
        label: "View contract",
        homePath: "/dashboard/contracts/",
    },
    {
        id: "duplicate-contract",
        label: "Duplicate contract",
        homePath: "/dashboard/contracts/",
    },
    {
        id: "edit-contract",
        label: "Edit contract",
        homePath: "/dashboard/contracts/",
    },
    {
        id: "edit-order",
        label: "Edit order status",
        homePath: "/dashboard/orders/",
    },
    {
        id: "link-order",
        label: "Link order to contract",
        homePath: "/dashboard/orders/",
    },
    {
        id: "view-order",
        label: "View order",
        homePath: "/dashboard/orders/",
    },
    {
        id: "add-outcome",
        label: "New outcome",
        homePath: "/dashboard/orders/",
    },
    {
        id: "generate-bpf",
        label: "New batch payment file",
        homePath: "/dashboard/finances/bpf/",
    },
    {
        id: "task-details",
        label: "Task details",
        homePath: "/tasks/ongoing-tasks/",
    },
    {
        id: "upload-file",
        label: "Upload new file",
        homePath: "/dashboard/contracts/",
    },
    {
        id: "outcome-details",
        label: "Outcome details",
        homePath: "/dashboard/outcomes/",
    },
    {
        id: "financial-conditions-details",
        label: "Financial conditions details",
        homePath: "/dashboard/financial-conditions/",
    },
    {
        id: "external-codes",
        label: "External codes",
        homePath: "/dashboard/regions/",
    },
    {
        id: "claim-details",
        label: "Claim details",
        homePath: "/dashboard/claims/",
    },
    {
        id: "/process/process-details",
        label: "Claim details",
        homePath: "/dashboard/claims/",
    },
    {
        id: "/process/select-contract",
        label: "Contract selection",
        homePath: "/dashboard/claims/",
    },
    {
        id: "/finances/bpf-details/",
        label: "BPF details",
        homePath: "/dashboard/finances/bpf/",
    },
    {
        id: "/claims/edit-claim/",
        label: "Edit claim",
        homePath: "/dashboard/claims/",
    },
];
