// Icons
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";

//Lib
import React, { useState } from "react";
import classnames from "classnames";
import { DatePicker } from "@mui/x-date-pickers";
//Utils
import { convertDateForAPI, datePlaceholder } from "@utils";

// Styles
import * as style from "./style.module.scss";
import IconButton from "@mui/material/IconButton";
import { Box, Typography } from "@mui/material";

/**
 * Props type
 */
interface Props {
    onChange: (date: any) => void;
    value: string;
    onClear?: () => void;
    placeholder?: string;
}

/**
 * Date Filter
 */
const DateFilter: React.FC<Props> = ({
    onChange,
    value,
    onClear,
    placeholder,
}) => {
    const [open, setOpen] = useState(false);
    return (
        <DatePicker
            value={value ? datePlaceholder(value) : null}
            open={open}
            className={classnames(style.datePicker)}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={value => {
                if (!value || !value?.isValid) {
                    onChange("");
                    return;
                }

                if (value) {
                    onChange(convertDateForAPI(value));
                }
            }}
            format="dd/MM/yyyy"
            slotProps={{
                textField: {
                    variant: "outlined",
                    autoComplete: "off",
                    sx: {
                        svg: { color: "#036", opacity: "0.5" },
                    },
                    size: "small",
                    fullWidth: true,
                    placeholder: placeholder || "dd/mm/yyyy",
                    required: true,
                    name: "dateFilter",
                    InputProps: {
                        style: undefined,
                        startAdornment: !!onClear && !!placeholder && (
                            <Box className={style.datePickerInput}>
                                {placeholder}
                                <Typography
                                    className={style.filterCounter}
                                    variant="button"
                                    color="primary"
                                >
                                    {"1"}
                                </Typography>
                                <Typography variant="button" color="primary">
                                    {"Selected"}
                                </Typography>
                            </Box>
                        ),
                        endAdornment: (
                            <React.Fragment>
                                <IconButton onClick={() => setOpen(true)}>
                                    <CalendarTodayIcon />
                                </IconButton>
                                {onClear && (
                                    <IconButton onClick={onClear}>
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </React.Fragment>
                        ),
                    },
                    inputProps: {
                        style: onClear
                            ? {
                                  display: "none",
                              }
                            : undefined,
                    },
                },
            }}
        />
    );
};

export default React.memo(DateFilter);
