import { useEffect } from "react";
import { useAxios } from "./useAxios";
import type { Api } from "@types";

const BASE_URI = `/v0`;

/**
 * External codes
 */
export const useExternalCodes = (
    path: string,
    regionId: string,
    getList = true,
) => {
    const url = `${BASE_URI}/${path}/${regionId}/externalCodes`;

    const {
        response: list,
        loading: fetching,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        {
            errorHandler: `Failed to fetch external codes`,
        },
    );

    const {
        loading: creating,
        fetch: create,
        error: createError,
    }: Api = useAxios({
        method: "POST",
        url,
    });

    const {
        loading: updating,
        fetch: update,
        error: updateError,
    }: Api = useAxios({
        method: "PUT",
    });

    useEffect(() => {
        if (!getList) return;
        loadList();
    }, []);

    return {
        list: list,
        loading: { creating, fetching, updating },
        error: {
            listError,
            createError,
            updateError,
        },
        create: listItem => {
            const name =
                listItem?.externalRegionName ||
                listItem?.externalAccountName ||
                listItem?.externalProductName ||
                listItem?.externalContractName;
            return create(
                { data: listItem },
                {
                    errorHandler: `Failed to add "${name}"`,
                    successHandler: `"${name}" was successfully added`,
                },
            );
        },
        update: (externalCodeId: string, listItem: any) => {
            const name =
                listItem?.externalRegionName ||
                listItem?.externalAccountName ||
                listItem?.externalProductName ||
                listItem?.externalContractName;
            return update(
                { url: `${url}/${externalCodeId}`, data: listItem },
                {
                    errorHandler: `Failed to update "${name}"`,
                    successHandler: `"${name}" was successfully updated`,
                },
            );
        },

        load: loadList,
    };
};
