// Icons
import InfoIcon from "@mui/icons-material/Info";

// Lib
import { useFormik, FormikProps } from "formik";
import {
    TextField,
    Typography,
    InputLabel,
    InputAdornment,
    Tooltip,
    CircularProgress,
    Grid,
    Box,
    FormHelperText,
} from "@mui/material";
import debounce from "lodash.debounce";
import React, {
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";

import { useRecoilState } from "recoil";

// Hooks
import {
    useContractPartners,
    useUpdateStep,
    useValidateDuplication,
} from "@hooks";

// Atoms
import { BPFGeneralInformationState } from "@atoms";
import { Autocomplete, Dialog, Dropzone, FormFooter } from "@components";

// Schemas
import {
    volumeBasedBPFGeneralInformation,
    performanceBasedBPFGeneralInformation,
    apheresisBasedBPFGeneralInformation,
} from "@schemas";
import { Account } from "@types";

const BPF_NAME_REGEX = /^[a-zA-Z0-9\s]*$/;

/**
 * Props type
 */
interface Props {
    disabled?: boolean;
    flowState?: string;
    location?: Location;
    isApheresisBased?: boolean;
    isVolumeBased?: boolean;
    isPerformanceBased?: boolean;
    onReset?: () => void;
}

type BPFGeneralInformation = {
    bpfName: string;
    paymentReference: string;
    file: File | undefined;
    contractPartner: Account | undefined;
};

/**
 * BPF General Information
 */
const BPFGeneralInformation = React.forwardRef(
    (
        {
            disabled,
            flowState,
            location,
            isApheresisBased,
            isVolumeBased,
            isPerformanceBased,
            onReset,
        }: Props,
        ref,
    ) => {
        /**
         * BPF General Information State
         */
        const [values, updateValuesState] = useRecoilState(
            BPFGeneralInformationState,
        );

        /**
         * Store a flag to reset the next pages if the user deletes contract partner
         */
        const [showWarning, setShowWarning] = useState(false);

        //Formik state
        const formik: FormikProps<BPFGeneralInformation> =
            useFormik<BPFGeneralInformation>({
                enableReinitialize: true,
                validateOnMount: true,
                initialValues: { ...values },
                validationSchema: isPerformanceBased
                    ? performanceBasedBPFGeneralInformation
                    : isVolumeBased
                      ? volumeBasedBPFGeneralInformation
                      : apheresisBasedBPFGeneralInformation,
                onSubmit: () => undefined,
            });

        /**
         * Partners
         */
        // Fetch partners
        const {
            getPartners,
            response: partners,
            loading: partnersLoading,
        } = useContractPartners();

        /**
         * Handle file upload
         */
        const handleFileUpload = useCallback(
            (file: File) => {
                formik.setFieldValue("file", file);
                // Make sure to set touched after setting value
                formik.setFieldTouched("file", true, false); // The third parameter false prevents immediate validation
            },
            [formik],
        );

        /**
         * Handle file removal
         */
        const handleFileRemoval = useCallback(() => {
            formik.setFieldValue("file", undefined);
            // Make sure to set touched after setting value
            formik.setFieldTouched("file", true, false); // The third parameter false prevents immediate validation
        }, [formik]);

        /**
         * Update step validation
         */
        const updateStepValidation = useUpdateStep(location, flowState);

        /**
         * Check the uniqueness of bpf name
         */
        const {
            validate: validateBpfName,
            isUniqueValue: isBpfNameUniqueValue,
            isNotUniqueValue: isBpfNameNotUniqueValue,
            isNotUniqueMessage: isBpfNameNotUniqueMessage,
            loading: bpfNameDuplicationValidating,
        } = useValidateDuplication("bpfName");

        /**
         * Check the uniqueness of bpf name
         */
        const {
            validate: validatePaymentReference,
            isUniqueValue: isPaymentReferenceUniqueValue,
            isNotUniqueValue: isPaymentReferenceNotUniqueValue,
            isNotUniqueMessage: isPaymentReferenceNotUniqueMessage,
            loading: paymentReferenceDuplicationValidating,
        } = useValidateDuplication("paymentReference");

        /**
         * Debouncers
         */
        const debounceLoadBpfNameData = useCallback(
            debounce(
                name =>
                    !!validateBpfName &&
                    validateBpfName(undefined, { bpfName: name }),
                600,
            ),
            [],
        );

        useEffect(() => {
            if (!formik?.values?.bpfName) {
                return;
            }
            debounceLoadBpfNameData(formik?.values?.bpfName);
        }, [formik?.values?.bpfName]);

        /**
         * Debouncers
         */
        const debounceLoadPaymentReferenceData = useCallback(
            debounce(
                name =>
                    !!validatePaymentReference &&
                    validatePaymentReference(undefined, {
                        paymentReference: name,
                    }),
                600,
            ),
            [],
        );

        useEffect(() => {
            if (!formik?.values?.paymentReference) {
                return;
            }
            debounceLoadPaymentReferenceData(formik?.values?.paymentReference);
        }, [formik?.values?.paymentReference]);

        /**
         * Check if the current step is valid
         * then set isPrepared to true, otherwise set it false
         */
        useEffect(() => {
            const generalRequiredFields =
                formik.isValid &&
                !bpfNameDuplicationValidating &&
                !!isBpfNameUniqueValue;

            if (isVolumeBased || isApheresisBased) {
                updateStepValidation(
                    generalRequiredFields &&
                        !paymentReferenceDuplicationValidating &&
                        !!isPaymentReferenceUniqueValue,
                );

                return;
            }

            updateStepValidation(generalRequiredFields);
        }, [
            formik.isValid,
            bpfNameDuplicationValidating,
            paymentReferenceDuplicationValidating,
            isBpfNameUniqueValue,
            isPaymentReferenceUniqueValue,
            isApheresisBased,
            isVolumeBased,
        ]);

        useImperativeHandle(ref, () => ({
            updateState() {
                updateValuesState(formik.values);
            },
        }));

        const onConfirm = async () => {
            if (!onReset) return;

            const copyValues = formik.values;

            await onReset();

            updateValuesState({ ...copyValues, contractPartner: undefined });
        };

        /**
         * Render
         */
        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height={1}
            >
                <Dialog
                    open={showWarning}
                    id={`dialog-confirm-cancel`}
                    message={
                        <Typography
                            variant="subtitle2"
                            color="black"
                            component="span"
                        >
                            By changing the payment partner, you will lose the
                            information currently stored for the BPF. Are you
                            sure want to continue?
                        </Typography>
                    }
                    primaryButton={{
                        text: "Yes",
                        action: () => {
                            onConfirm();
                            setShowWarning(false);
                        },
                        loading: false,
                    }}
                    secondaryButton={{
                        text: "No",
                        action: () => setShowWarning(false),
                    }}
                />
                <form
                    noValidate
                    onBlur={formik.handleBlur}
                    id={`BPF-general-info-form`}
                >
                    <Typography variant="h2" mb={1}>
                        General information
                    </Typography>
                    <Grid container mt={3} mb={7} spacing={3}>
                        {(!!isVolumeBased || !!isApheresisBased) && (
                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    error={
                                        (!!formik?.errors?.paymentReference &&
                                            !!formik?.touched
                                                ?.paymentReference) ||
                                        isPaymentReferenceNotUniqueValue
                                    }
                                    shrink
                                >
                                    {"Payment Registration Number (*)"}
                                </InputLabel>
                                <TextField
                                    id={`generate-bpf-paymentRef-input`}
                                    disabled={disabled}
                                    fullWidth
                                    size="small"
                                    name="paymentReference"
                                    autoComplete="off"
                                    value={formik?.values?.paymentReference}
                                    error={
                                        (!!formik?.errors?.paymentReference &&
                                            !!formik?.touched
                                                ?.paymentReference) ||
                                        isPaymentReferenceNotUniqueValue
                                    }
                                    onChange={(
                                        event: React.BaseSyntheticEvent,
                                    ) => {
                                        if (
                                            event.target.value.startsWith(" ")
                                        ) {
                                            formik.setFieldValue(
                                                "paymentReference",
                                                event.target.value.trimStart(),
                                            );
                                        } else
                                            formik.setFieldValue(
                                                "paymentReference",
                                                event.target.value,
                                            );
                                    }}
                                    variant="outlined"
                                    InputProps={
                                        paymentReferenceDuplicationValidating
                                            ? {
                                                  endAdornment: (
                                                      <InputAdornment position="start">
                                                          <CircularProgress
                                                              color="inherit"
                                                              size={20}
                                                          />
                                                      </InputAdornment>
                                                  ),
                                              }
                                            : isPaymentReferenceNotUniqueValue
                                              ? {
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip
                                                                open={true}
                                                                arrow
                                                                title={
                                                                    isPaymentReferenceNotUniqueMessage
                                                                }
                                                                placement="top"
                                                            >
                                                                <InfoIcon color="error" />
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                }
                                              : undefined
                                    }
                                />
                                {!!formik?.errors?.paymentReference &&
                                    !!formik?.touched?.paymentReference && (
                                        <FormHelperText error>
                                            {formik?.errors?.paymentReference}
                                        </FormHelperText>
                                    )}
                            </Grid>
                        )}

                        <Grid item xs={12} md={6}>
                            <InputLabel
                                error={
                                    (!!formik?.errors?.bpfName &&
                                        !!formik?.touched?.bpfName) ||
                                    isBpfNameNotUniqueValue
                                }
                                shrink
                            >
                                {"BPF name (*)"}
                            </InputLabel>
                            <TextField
                                id={`generate-bpf-claim-reference`}
                                disabled={disabled}
                                fullWidth
                                size="small"
                                name="bpfName"
                                autoComplete="off"
                                value={formik?.values?.bpfName}
                                error={
                                    (!!formik?.errors?.bpfName &&
                                        !!formik?.touched?.bpfName) ||
                                    isBpfNameNotUniqueValue
                                }
                                onChange={(event: React.BaseSyntheticEvent) => {
                                    if (
                                        BPF_NAME_REGEX.test(event.target.value)
                                    ) {
                                        if (
                                            event.target.value.startsWith(" ")
                                        ) {
                                            formik.setFieldValue(
                                                "bpfName",
                                                event.target.value.trimStart(),
                                            );
                                        } else
                                            formik.setFieldValue(
                                                "bpfName",
                                                event.target.value,
                                            );
                                    }
                                }}
                                variant="outlined"
                                InputProps={
                                    bpfNameDuplicationValidating
                                        ? {
                                              endAdornment: (
                                                  <InputAdornment position="start">
                                                      <CircularProgress
                                                          color="inherit"
                                                          size={20}
                                                      />
                                                  </InputAdornment>
                                              ),
                                          }
                                        : isBpfNameNotUniqueValue
                                          ? {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            open={true}
                                                            arrow
                                                            title={
                                                                isBpfNameNotUniqueMessage
                                                            }
                                                            placement="top-start"
                                                        >
                                                            <InfoIcon color="error" />
                                                        </Tooltip>
                                                    </InputAdornment>
                                                ),
                                            }
                                          : undefined
                                }
                            />
                            {!!formik?.errors?.bpfName &&
                                !!formik?.touched?.bpfName && (
                                    <FormHelperText error>
                                        {formik?.errors?.bpfName}
                                    </FormHelperText>
                                )}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InputLabel
                                id={`partner-label`}
                                error={
                                    !!formik?.errors?.contractPartner &&
                                    !!formik?.touched?.contractPartner
                                }
                                shrink
                            >
                                {"Primary contract partner (*)"}
                            </InputLabel>

                            <Autocomplete
                                id={`partner-input`}
                                data={partners?.data?.partners}
                                loading={partnersLoading}
                                name="contractPartner"
                                value={formik?.values?.contractPartner}
                                size="small"
                                onBlur={event => {
                                    formik.handleBlur(event);
                                }}
                                fullWidth
                                keysToMatch={[
                                    "accountName",
                                    "accountCity",
                                    "sapAccountCode",
                                    "knowyoursupplierid",
                                ]}
                                onChange={value => {
                                    // set blur when user click on (X) delete the current partner
                                    if (!formik.touched["contractPartner"]) {
                                        formik.setTouched({
                                            ...formik.touched,
                                            contractPartner: true,
                                        });
                                    }
                                    if (!value) {
                                        setShowWarning(true);
                                        return;
                                    }
                                    formik.setFieldValue(
                                        "contractPartner",
                                        value,
                                    );
                                }}
                                onSearch={(query: string) => {
                                    getPartners(query);
                                }}
                                disabled={disabled}
                                variant="outlined"
                                error={
                                    !!formik?.errors?.contractPartner &&
                                    !!formik?.touched?.contractPartner
                                }
                            />
                            {!!formik?.errors?.contractPartner &&
                                !!formik?.touched?.contractPartner && (
                                    <FormHelperText error>
                                        {
                                            (
                                                formik?.errors
                                                    ?.contractPartner as unknown as Record<
                                                    string,
                                                    string
                                                >
                                            )?.accountTypeId
                                        }
                                    </FormHelperText>
                                )}
                        </Grid>

                        {isApheresisBased && (
                            <Grid item xs={12} mt={4}>
                                <InputLabel
                                    shrink
                                    error={
                                        !!formik?.errors?.file &&
                                        !!formik?.touched?.file
                                    }
                                >
                                    {"Evidence (*)"}
                                </InputLabel>
                                <Dropzone
                                    disabled={!!formik?.values?.file}
                                    variant="big"
                                    id={"upload-contract-document"}
                                    fileTypes={{
                                        "application/pdf": [".pdf"],
                                        "image/png": [".png"],
                                        "image/jpeg": [".jpeg", ".jpg"],
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                            [".docx"],
                                        "application/msword": [".doc"],
                                        "application/vnd.ms-excel": [".xls"],
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                            [".xlsx"],
                                        "application/vnd.ms-outlook": [".msg"],
                                    }}
                                    files={[formik?.values?.file]}
                                    onUpload={uploadFiles => {
                                        handleFileUpload(uploadFiles);
                                    }}
                                    onAttachmentRemove={() => {
                                        handleFileRemoval();
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </form>
                <FormFooter
                    id={`general-information-generate-bpf-footer`}
                    error={
                        (!!formik?.errors?.bpfName &&
                            !!formik?.touched?.bpfName) ||
                        ((isVolumeBased || isApheresisBased) &&
                            !!formik?.errors?.paymentReference &&
                            !!formik?.touched?.paymentReference) ||
                        (isApheresisBased &&
                            !formik?.values?.file &&
                            !!formik?.touched?.file) ||
                        (!!(
                            formik?.errors
                                ?.contractPartner as unknown as Record<
                                string,
                                string
                            >
                        )?.accountTypeId &&
                            !!formik?.touched?.contractPartner)
                    }
                    textAlign="right"
                />
            </Box>
        );
    },
);

export default BPFGeneralInformation;
