// icon
import { Add } from "@mui/icons-material";
import {
    Button,
    Grid,
    InputLabel,
    Typography,
    Box,
    FormHelperText,
} from "@mui/material";

//lib
import React, { useCallback, useState, useEffect } from "react";
import clonedeep from "lodash.clonedeep";

//Own components
import { Autocomplete, GrayBox } from "@components";

//types
import { Account } from "@types";

//import utils
import { isArrayWithContent } from "@utils";

//import style
import * as style from "./style.module.scss";

/**
 * Props type
 */
interface Props {
    values: Array<any>;
    onChange: (fields: any) => void;
    errors?: Record<string, string> | Array<any>;
    touched?: Record<string, string> | Array<any>;
    onBlur?: (event: React.SyntheticEvent) => void;
    setTouched?: any;
    partners: {
        loading: boolean;
        data: Array<Account>;
        onSearch: (query: string) => void;
    };
    id: string;
    primaryPartnerId: string;
    duplicationError?: { index: number; error: string };
    disabled?: boolean;
    hasOnlyOnePartner?: boolean;
}

/**
 * Contract partners
 */
const ContractPartnersForm: React.FC<Props> = ({
    id,
    values,
    onChange,
    errors,
    touched,
    onBlur,
    partners,
    primaryPartnerId,
    disabled,
    hasOnlyOnePartner,
}) => {
    const [itemInEditProcessIndex, setItemInEditProcess] = useState<number>(-1);
    const [duplicationError, setDuplicationError] = useState<{
        index: number | undefined;
        error: string;
    }>({
        index: undefined,
        error: "",
    });

    /**
     * Toggle edit  mode
     */
    useEffect(() => {
        if (!isArrayWithContent(values)) {
            setItemInEditProcess(-1);
            return;
        }
        const valueInEditMode = values.findIndex(value => !value?.accountId);
        setItemInEditProcess(valueInEditMode);
    }, [values]);

    /**
     * Add new account
     */
    const addPartner = () => {
        const copyPartners = clonedeep(values);

        copyPartners.push({
            accountName: "",
        });

        onChange(copyPartners);
    };

    /**
     * Delete account
     */
    const deleteAccount = (index: number) => {
        const copyPartners = clonedeep(values);

        const filteredRole = copyPartners.filter((_, idx) => idx !== index);

        onChange(filteredRole);
        setDuplicationError({ index: undefined, error: "" });
    };

    /**
     * map formik errors
     */
    const hasError = useCallback(
        (index: number) => {
            return (
                !!errors &&
                errors &&
                errors[index] &&
                !!touched &&
                touched[index]
            );
        },
        [errors, touched],
    );

    const hasDuplicationPartners = (accountId: string, index: number) => {
        if (!accountId || !primaryPartnerId) return false;

        if (accountId === primaryPartnerId) {
            setDuplicationError({
                index,
                error: "Primary partner cannot be set as an additional partner.",
            });
            return true;
        }

        const isDuplicate =
            isArrayWithContent(values) &&
            values.some(item => item?.accountId === accountId);

        if (isDuplicate) {
            setDuplicationError({
                index,
                error: "This additional partner is already present. Please choose a different partner.",
            });
            return true;
        }
        setDuplicationError({ index: undefined, error: "" });
        return false;
    };

    /**
     * Change handler
     */
    const onPartnerChange = (index: number, value) => {
        const copyPartners = clonedeep(values);

        const isDuplicate = hasDuplicationPartners(value.accountId, index);

        if (isDuplicate) return;

        copyPartners[index] = value;
        onChange(copyPartners);
    };

    /**
     * Render
     */
    return (
        <form
            noValidate
            onBlur={onBlur}
            className={style.wrapper}
            id={`${id}-partner-form`}
        >
            <Grid container item xs={12} mb={5}>
                {isArrayWithContent(values) &&
                    values.map((item: Account, accountIdx: number) => (
                        <Grid item xs={12} key={accountIdx} mb={3}>
                            <GrayBox
                                className={style.roleSection}
                                padding={2}
                                id={`${id}-gray-box`}
                                primaryAction={{
                                    hidden: hasOnlyOnePartner,
                                    onClick: () => deleteAccount(accountIdx),
                                    text: "Remove partner",
                                    sx: {
                                        padding: "1rem 0.5rem",
                                    },
                                    disabled:
                                        itemInEditProcessIndex > -1 &&
                                        itemInEditProcessIndex !== accountIdx,
                                }}
                                header={
                                    <Box px={3} py={1}>
                                        <InputLabel
                                            error={
                                                !!hasError(accountIdx) ||
                                                duplicationError?.index ===
                                                    accountIdx
                                            }
                                            id={`${id}-account`}
                                            shrink
                                        >
                                            {"Account (*)"}
                                        </InputLabel>

                                        <Autocomplete
                                            disabled={
                                                itemInEditProcessIndex > -1 &&
                                                itemInEditProcessIndex !==
                                                    accountIdx
                                            }
                                            data={partners.data}
                                            id={`${id}-partners`}
                                            loading={partners.loading}
                                            name={`${accountIdx}`}
                                            onBlur={event => {
                                                !!onBlur && onBlur(event);
                                            }}
                                            fullWidth
                                            value={item}
                                            size="small"
                                            keysToMatch={[
                                                "accountName",
                                                "accountCity",
                                                "sapAccountCode",
                                                "knowyoursupplierid",
                                            ]}
                                            onChange={value => {
                                                onPartnerChange(accountIdx, {
                                                    accountName: value
                                                        ? value.accountName
                                                        : "",
                                                    accountId: value
                                                        ? value.accountId
                                                        : "",
                                                    accountStatus: value
                                                        ? value.accountStatus
                                                        : "",
                                                });
                                            }}
                                            onSearch={(query: string) => {
                                                partners.onSearch(query);
                                            }}
                                            variant="outlined"
                                            error={
                                                !!hasError(accountIdx) ||
                                                duplicationError?.index ===
                                                    accountIdx
                                            }
                                        />
                                    </Box>
                                }
                                helper={
                                    duplicationError?.index === accountIdx ? (
                                        <Box px={3}>
                                            <FormHelperText error>
                                                {duplicationError?.error}
                                            </FormHelperText>
                                        </Box>
                                    ) : undefined
                                }
                            />
                        </Grid>
                    ))}
                {!hasOnlyOnePartner && (
                    <Grid item xs={8}>
                        <GrayBox
                            padding={2}
                            id={`${id}-add-role-btn`}
                            header={
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => addPartner()}
                                    startIcon={<Add />}
                                    size="large"
                                    disabled={
                                        itemInEditProcessIndex > -1 ||
                                        duplicationError?.index !== undefined ||
                                        disabled
                                    }
                                >
                                    <Typography
                                        variant="caption1"
                                        color={
                                            disabled ||
                                            itemInEditProcessIndex > -1 ||
                                            duplicationError?.index !==
                                                undefined
                                                ? "action.disabled"
                                                : "primary"
                                        }
                                    >
                                        {`Add additional partner`}
                                    </Typography>
                                </Button>
                            }
                        />
                    </Grid>
                )}
            </Grid>
        </form>
    );
};
export default React.memo(ContractPartnersForm);
