//import libs
import {
    Radio,
    TableCell,
    TableRow,
    Typography,
    Tooltip,
    Checkbox,
} from "@mui/material";
import React, { useMemo } from "react";

//utils
import { displayDate, isArrayWithContent, truncateArray } from "@utils";

//style
import * as style from "./style.module.scss";
import { Contract } from "@types";

interface Props {
    row: Contract & {
        brands: Array<{ name: string; id: string }>;
        categories: Array<{ categoryLabel: string; categoryValue: string }>;
    };
    rowIndex: number;
    id: string;
    callbacks: any;
    disabled?: boolean;
}

const ClaimContractSelectionRow = ({
    row,
    rowIndex,
    id,
    callbacks,
    disabled,
}: Props) => {
    /**
     * Get selected items
     */
    const selectedItems = useMemo(() => {
        if (
            !callbacks?.selectedItems ||
            !isArrayWithContent(callbacks?.selectedItems)
        )
            return [];

        return callbacks?.selectedItems;
    }, [callbacks?.selectedItems]);

    /**
     * Check if the item is selected
     * don't return undefined to avoid warnings
     */
    const isItemSelected = useMemo(() => {
        if (!row?.id || !selectedItems || !isArrayWithContent(selectedItems))
            return false;
        return selectedItems?.some(item => item.id === row?.id);
    }, [selectedItems, row?.id]);

    /**
     * Render Categories col
     */
    const mappedCategories = useMemo(() => {
        if (!isArrayWithContent(row?.categories)) return [];

        return row?.categories?.map(el => {
            return `${el.categoryLabel}: ${el.categoryValue}`;
        });
    }, [row?.categories]);

    /**
     * Render
     */
    return (
        <TableRow
            className={style.cursor}
            selected={selectedItems?.includes(row.id)}
            id={`${id}-row-${rowIndex}`}
            onClick={event => {
                if (!disabled) {
                    callbacks.onSelect(
                        row,
                        parseInt((event.target as HTMLInputElement).value),
                    );
                }
            }}
        >
            <TableCell width="4.6875rem">
                {callbacks.canSelectMany ? (
                    <Checkbox
                        onChange={event => {
                            callbacks.onSelect(
                                row,
                                parseInt(event.target.value),
                            );
                        }}
                        disabled={disabled}
                        checked={!!isItemSelected}
                        size="small"
                        id={`${id}-row-${rowIndex}-outcome-contract`}
                    />
                ) : (
                    <Radio
                        onChange={event => {
                            callbacks.onSelect(
                                row,
                                parseInt(event.target.value),
                            );
                        }}
                        checked={!!isItemSelected}
                        value={rowIndex}
                        inputProps={{ "aria-label": `${id}-row-${rowIndex}` }}
                        required
                        id={`${id}-row-${rowIndex}-outcome-contract`}
                        disabled={disabled}
                    />
                )}
            </TableCell>
            <TableCell>
                <Typography variant="caption2">{row.reference}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {displayDate(row.startDate)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {displayDate(row.endDate)}
                </Typography>
            </TableCell>
            <TableCell>
                {isArrayWithContent(row.brands) ? (
                    <Tooltip
                        arrow
                        placement="top"
                        title={truncateArray(row.brands, undefined, "name")}
                    >
                        <Typography variant="caption2">
                            {truncateArray(row.brands, 2, "name")}
                        </Typography>
                    </Tooltip>
                ) : (
                    "-"
                )}
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {row?.primaryContractPartner?.name}
                </Typography>
            </TableCell>
            <TableCell>
                {isArrayWithContent(row.categories) ? (
                    <Tooltip
                        arrow
                        placement="top"
                        title={truncateArray(mappedCategories, undefined)}
                    >
                        <Typography variant="caption2">
                            {truncateArray(mappedCategories, 2)}
                        </Typography>
                    </Tooltip>
                ) : (
                    "-"
                )}
            </TableCell>
        </TableRow>
    );
};

export default ClaimContractSelectionRow;
