//import libs
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Box, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import React, { useMemo } from "react";

//utils
import { isArrayWithContent, truncateArray } from "@utils";

// import own components
import { ActionMenu } from "@components";

//style
import * as style from "./style.module.scss";
import * as mainStyle from "../../style.module.scss";
import { ContractTeam } from "@types";

interface Props {
    row: ContractTeam;
    callbacks?: any;
    rowIndex: number;
    id: string;
    isColVisible: (col: string) => boolean;
    mapper: any;
}

const ContractTeamsRow = ({
    row,
    rowIndex,
    callbacks,
    id,
    mapper,
    isColVisible,
}: Props) => {
    const mapCountries = useMemo(() => {
        if (!row?.countries && !isArrayWithContent(row?.countries)) return [];
        return row.countries?.map(country => country.name);
    }, [row?.countries]);

    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
            className={mainStyle.row}
        >
            <TableCell sx={{ width: "3.125rem" }}>
                <Box position="relative">
                    <PersonOutlineIcon className={style.icon} />
                    <PersonOutlineIcon
                        className={style.icon}
                        sx={{
                            position: "absolute",
                            right: "-12px",
                            bottom: "8px",
                        }}
                    />
                </Box>
            </TableCell>

            {isColVisible("teamName") && (
                <TableCell>
                    <Typography variant="caption2">{row.teamName}</Typography>
                </TableCell>
            )}

            {isColVisible("countries") && (
                <TableCell>
                    <Tooltip
                        arrow
                        placement="top"
                        title={truncateArray(mapCountries)}
                    >
                        <Typography variant="caption2">
                            {truncateArray(mapCountries, 3)}
                        </Typography>
                    </Tooltip>
                </TableCell>
            )}

            {isColVisible("therapeuticAreaIds") && (
                <TableCell>
                    <Typography variant="caption2">
                        {mapper &&
                            mapper("therapeuticAreas", row.therapeuticAreaIds)}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("users") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.users?.length}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("minimumContractReviewers") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.minimumContractReviewers}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("minimumFinancialApprovers") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.minimumFinancialApprovers}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right" className={mainStyle.action}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <ActionMenu
                        id={`${id}-row-${rowIndex}-contract-team`}
                        stopPropagation
                        options={[
                            {
                                label: "Edit team",
                                action: () =>
                                    callbacks &&
                                    callbacks.onEditTeam(row, rowIndex),
                            },
                            {
                                label: "Refresh reporting data",
                                action: () =>
                                    callbacks &&
                                    callbacks.onRefreshReportingData(
                                        row,
                                        rowIndex,
                                    ),
                            },
                        ]}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default ContractTeamsRow;
