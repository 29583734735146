export const ROWRENDERERCONST = {
    CONTRACTS: "CONTRACTS",
    CONTRACT_TEAMS: "CONTRACT_TEAMS",
    ORDERS: "ORDERS",
    OUTCOMES: "OUTCOMES",
    OUTCOMES_FINANCIAL_CONDITIONS: "OUTCOMES_FINANCIAL_CONDITIONS",
    OUTCOME_CONTRACT_SELECTION: "OUTCOME_CONTRACT_SELECTION",
    OUTCOME_ORDER_SELECTION: "OUTCOME_ORDER_SELECTION",
    PARTNERS: "PARTNERS",
    PRICES: "PRICES",
    PRODUCTS: "PRODUCTS",
    ONGOING_TASKS: "ONGOING_TASKS",
    COMPLETED_TASKS: "COMPLETED_TASKS",
    TASKS: "TASKS",
    FINANCES_OUTCOMES: "FINANCES_OUTCOMES",
    BPF: "BPF",
    CREDIT_NOTES_OUTCOME: "CREDIT_NOTES_OUTCOME",
    CREDIT_NOTES_CLAIM: "CREDIT_NOTES_CLAIM",
    INVOICE_RELEASE: "INVOICE_RELEASE",
    TRANSACTION_VALUES: "TRANSACTION_VALUES",
    BILLING_PLAN: "BILLING_PLAN",
    MILESTONE: "MILESTONE",
    FINANCIAL_CONDITIONS: "FINANCIAL_CONDITIONS",
    MAINTENANCE_FINANCIAL_CONDITIONS: "MAINTENANCE_FINANCIAL_CONDITIONS",
    PRODUCT_CONDITIONS: "PRODUCT_CONDITIONS",
    OUTCOME_FINANCIAL_TRANSACTIONS: "OUTCOME_FINANCIAL_TRANSACTIONS",
    REGION: "REGION",
    CONTEXT: "CONTEXT",
    EXTERNAL_CODES: "EXTERNAL_CODES",
    MODEL: "MODEL",
    CATEGORY: "CATEGORY",
    LINK_CATEGORY: "LINK_CATEGORY",
    CONTRACT_CATEGORY: "CONTRACT_CATEGORY",
    SCALES_OVERVIEW: "SCALES_OVERVIEW",
    SCALES_WITH_ACTION: "SCALES_WITH_ACTION",
    CLAIM: "CLAIM",
    PRICE_CORRECTION_CLAIMS: "PRICE_CORRECTION_CLAIMS",
    CLAIM_CONTRACT: "CLAIM_CONTRACT",
    BPF_CLAIMS: "BPF_CLAIMS",
    CLAIM_FINANCIAL_TRANSACTIONS: "CLAIM_FINANCIAL_TRANSACTIONS",
    MAINTENANCE_USERS: "MAINTENANCE_USERS",
    MAINTENANCE_ROLES: "MAINTENANCE_ROLES",
    __PICKED_TEAM: "__PICKED_TEAM", // Stored team in local storage
    BPF_PRICE_CORRECTION_CLAIMS: "BPF_PRICE_CORRECTION_CLAIMS",
    ADVANCED_SCALES_WITH_ACTION: "ADVANCED_SCALES_WITH_ACTION",
    BPF_OUTCOME_CONTRACT_SELECTION: "BPF_OUTCOME_CONTRACT_SELECTION",
};

export const filterByCountry = (headers, countryIsoCode) => {
    return headers.filter(
        item =>
            !item?.showForCountries?.length ||
            item?.showForCountries.includes(countryIsoCode),
    );
};

export const HEADERS = {
    CONTRACTS: [
        {
            header: "",
            viewingOption: "",
            rowKey: "",
        },
        {
            header: "Ref",
            viewingOption: "Contract reference",
            rowKey: "reference",
        },
        {
            header: "Brand",
            viewingOption: "Brand",
            rowKey: "brandName",
        },
        {
            header: "Therapeutic area",
            viewingOption: "Therapeutic area",
            rowKey: "therapeuticAreaName",
        },
        {
            header: "Primary contract partner",
            viewingOption: "Primary contract partner",
            rowKey: "primaryContractPartner",
        },
        {
            header: "Additional partners",
            viewingOption: "Additional partners",
            rowKey: "additionalPartners",
        },
        {
            header: "Product",
            viewingOption: "Product",
            rowKey: "products",
            unchecked: true,
        },
        {
            header: "Categories",
            viewingOption: "Categories",
            rowKey: "categories",
            unchecked: true,
        },
        {
            header: "start date",
            viewingOption: "Start date",
            rowKey: "validFrom",
        },
        {
            header: "end date",
            viewingOption: "End date",
            rowKey: "validTo",
        },
        {
            header: "Status",
            viewingOption: "Status",
            rowKey: "status",
        },
        {
            header: "",
            viewingOption: "",
            rowKey: "",
        },
    ],
    CONTRACT_TEAMS: [
        {
            header: "",
        },
        {
            header: "Team Name",
            viewingOption: "Team name",
            rowKey: "teamName",
        },
        {
            header: "Country",
            viewingOption: "Country",
            rowKey: "countries",
        },
        {
            header: "Therapeutic area",
            viewingOption: "Therapeutic area",
            rowKey: "therapeuticAreaIds",
        },
        {
            header: "Members",
            viewingOption: "Members",
            rowKey: "users",
        },
        {
            header: "Min. contract reviewers",
            viewingOption: "Min. contract reviewers",
            rowKey: "minimumContractReviewers",
        },
        {
            header: "Min. financial approvers",
            viewingOption: "Min. financial approvers",
            rowKey: "minimumFinancialApprovers",
        },
        {
            header: "",
        },
    ],
    ORDERS: [
        {
            header: "Treatment site",
            viewingOption: "Treatment site",
            rowKey: "treatmentSite",
        },
        {
            header: "Infusion date",
            viewingOption: "Infusion date",
            rowKey: "infusionDate",
        },
        {
            header: "Cquence Id",
            viewingOption: "Cquence Id",
            rowKey: "cquenceId",
        },
        {
            header: "Contract ref",
            viewingOption: "Contract reference",
            rowKey: "contractReference",
        },
        {
            header: "Brand",
            viewingOption: "Brand",
            rowKey: "brand",
        },
        {
            header: "SAP Order Number",
            viewingOption: "SAP Order Number",
            rowKey: "sapOrderNo",
        },
        {
            header: "Order date",
            viewingOption: "Order date",
            rowKey: "orderDate",
        },
        {
            header: "Order status",
            viewingOption: "Order status",
            rowKey: "orderStatus",
        },
        {
            header: "Indication",
            viewingOption: "Indication",
            rowKey: "indication",
            unchecked: true,
        },
        {
            header: "",
            viewingOption: "",
        },
    ],
    OUTCOMES: [
        {
            header: "Ext. contract ref",
            viewingOption: "Ext. contract ref",
            rowKey: "contractRef",
        },
        {
            header: "Cquence id",
            viewingOption: "Cquence Id",
            rowKey: "salessystemOrderId",
        },
        {
            header: "infusion date",
            viewingOption: "Infusion date",
            rowKey: "infusionDate",
        },
        {
            header: "milestone",
            viewingOption: "Milestone",
            rowKey: "milestoneNumber",
        },
        {
            header: "action",
            viewingOption: "Outcome action",
            rowKey: "outcomeAction",
        },
        {
            header: "date received",
            viewingOption: "Date received",
            rowKey: "outcomeDate",
        },
        {
            header: "Status",
            viewingOption: "Status",
            rowKey: "status",
        },
        {
            header: "",
            viewingOption: "",
        },
    ],
    OUTCOMES_FINANCIAL_CONDITIONS: [
        {
            header: "Ext. contract ref",
            viewingOption: "Ext. contract ref",
            rowKey: "contractRef",
        },
        {
            header: "Cquence id",
            viewingOption: "Cquence Id",
            rowKey: "salessystemOrderId",
        },
        {
            header: "infusion date",
            viewingOption: "Infusion date",
            rowKey: "infusionDate",
        },
        {
            header: "Financial trigger type",
            viewingOption: "Financial trigger type",
            rowKey: "financialTriggerType",
        },
        {
            header: "action",
            viewingOption: "Financial conditions action",
            rowKey: "outcomeAction",
        },
        {
            header: "Status",
            viewingOption: "Status",
            rowKey: "status",
        },
        {
            header: "",
            viewingOption: "",
        },
    ],
    //split on | for rendering on 2 lines
    OUTCOME_CONTRACT_SELECTION: [
        {
            header: "",
        },
        {
            header: "Ref",
        },
        {
            header: "Contract partner",
        },
        {
            header: "Additional partners",
        },
        {
            header: "product |& indication",
        },
        {
            header: "valid from |& to",
        },
    ],
    OUTCOME_ORDER_SELECTION: [
        {
            header: "",
        },
        {
            header: "product",
        },
        {
            header: "infusion date",
        },
        {
            header: "treatment site",
        },

        {
            header: "cquence id",
        },
    ],
    //split on | for rendering on 2 lines
    PARTNERS: [
        {
            header: "Name",
            viewingOption: "Name",
            rowKey: "name",
        },
        {
            header: "Account|type",
            viewingOption: "Account type",
            rowKey: "type",
        },
        {
            header: "Address",
            viewingOption: "Address",
            rowKey: "address",
        },
        {
            header: "SAP code",
            viewingOption: "SAP code",
            rowKey: "sapAccountCode",
        },
        {
            header: "Knowyour-|supplier id",
            viewingOption: "Knowyoursupplier id",
            rowKey: "knowyoursupplierid",
        },
        {
            header: "Bank type",
            viewingOption: "Bank type",
            rowKey: "bankType",
        },
        {
            header: "Country|of sale",
            viewingOption: "Country of sale",
            rowKey: "countryOfSale",
        },
        {
            header: "Cods ID",
            viewingOption: "Cods ID",
            rowKey: "codsId",
        },
        {
            header: "Trust account",
            viewingOption: "Trust account",
            rowKey: "trustAccountCode",
            showForCountries: ["GB"],
        },
        {
            header: "Region",
            viewingOption: "Region",
            rowKey: "regionCode",
            showForCountries: ["GB"],
        },
        {
            header: "CMU region",
            viewingOption: "CMU region",
            rowKey: "cmuRegionCode",
            showForCountries: ["GB"],
        },
        {
            header: "Sub region 1",
            viewingOption: "Sub region 1",
            rowKey: "regionCode1",
            showForCountries: ["GB"],
        },
        {
            header: "Sub region 2",
            viewingOption: "Sub region 2",
            rowKey: "regionCode2",
            showForCountries: ["GB"],
        },
        {
            header: "Status",
            viewingOption: "Status",
            rowKey: "status",
        },
        {
            header: "",
            viewingOption: "",
            rowKey: "",
        },
    ],
    PRICES: [
        {
            header: "CONDITION",
        },
        {
            header: "VALID FROM",
        },

        {
            header: "VALID TILL",
        },

        {
            header: "PRICE",
        },
        {
            header: "CURRENCY",
        },
        {
            header: "",
        },
    ],
    FINANCIAL_CONDITIONS: [
        {
            header: "NAME",
        },
        {
            header: "TYPE",
        },
        {
            header: "DESCRIPTION",
        },
        {
            header: "",
        },
    ],
    PRODUCTS: [
        {
            header: "Name",
            viewingOption: "Name",
            rowKey: "productName",
        },
        {
            header: "Organisation",
            viewingOption: "Organisation",
            rowKey: "salesOrg",
        },
        {
            header: "SAP SKU number",
            viewingOption: "SAP SKU number",
            rowKey: "sapSkuNo",
        },

        {
            header: "Brand",
            viewingOption: "Brand",
            rowKey: "brandName",
        },
        {
            header: "Status",
            viewingOption: "Status",
            rowKey: "status",
        },
        {
            header: "Country",
            viewingOption: "Country",
            rowKey: "country",
        },
        {
            header: "",
            viewingOption: "",
            rowKey: "",
        },
    ],
    ONGOING_TASKS: [
        {
            header: "Name",
            viewingOption: "Name",
            rowKey: "name",
        },
        {
            header: "Contract Ref",
            viewingOption: "Contract reference",
            rowKey: "contractRef",
        },
        {
            header: "Task definition key",
            viewingOption: "Task definition key",
            rowKey: "taskDefinitionKey",
        },
        {
            header: "Category",
            viewingOption: "Category",
            rowKey: "category",
        },
        {
            header: "Cquence id",
            viewingOption: "Cquence id",
            rowKey: "salessystemOrderId",
        },
        {
            header: "Supplier name",
            viewingOption: "Supplier name",
            rowKey: "supplierName",
            unchecked: true,
        },
        {
            header: "Claim number",
            viewingOption: "Claim number",
            rowKey: "claimNo",
            unchecked: true,
        },
        {
            header: "Date added",
            viewingOption: "Date added",
            rowKey: "created",
        },
    ],
    COMPLETED_TASKS: [
        {
            header: "Name",
            viewingOption: "Name",
            rowKey: "name",
        },
        {
            header: "Contract Ref",
            viewingOption: "Contract reference",
            rowKey: "contractRef",
        },
        {
            header: "Task definition key",
            viewingOption: "Task definition key",
            rowKey: "taskDefinitionKey",
        },
        {
            header: "Category",
            viewingOption: "Category",
            rowKey: "category",
        },
        {
            header: "Cquence id",
            viewingOption: "Cquence id",
            rowKey: "salessystemOrderId",
        },
        {
            header: "Supplier name",
            viewingOption: "Supplier name",
            rowKey: "supplierName",
            unchecked: true,
        },
        {
            header: "Claim number",
            viewingOption: "Claim number",
            rowKey: "claimNo",
            unchecked: true,
        },
        {
            header: "Date added",
            viewingOption: "Date added",
            rowKey: "created",
        },
        {
            header: "Approved",
            viewingOption: "Approved",
            rowKey: "approved",
        },
        {
            header: "",
            viewingOption: "",
            rowKey: "",
        },
    ],
    FINANCES_OUTCOMES: [
        {
            header: "",
        },
        {
            header: "Contract ref",
        },
        {
            header: "TherapeuticArea name",
        },
        {
            header: "Product name",
        },
        {
            header: "SAP SKU number",
        },
        {
            header: "Cquence id",
        },
        {
            header: "Creation date|End date",
        },
        {
            header: "Amount",
        },

        {
            header: "",
        },
    ],
    BPF: [
        {
            header: "BPF Name",
            viewingOption: "BPF Name",
            rowKey: "bpfName",
        },
        {
            header: "Date processed",
            viewingOption: "Date processed",
            rowKey: "dateProcessed",
        },
        {
            header: "Contract ref",
            viewingOption: "Contract reference",
            rowKey: "contractRef",
        },
        {
            header: "Contract type",
            viewingOption: "Contract type",
            rowKey: "contractType",
        },
        {
            header: "Payment Registration Number",
            viewingOption: "Payment Registration Number",
            rowKey: "customerRef",
        },
        {
            header: "Contract partner",
            viewingOption: "Contract partner",
            rowKey: "primaryContractPartner",
        },
        {
            header: "type",
            viewingOption: "type",
            rowKey: "financeType",
        },
        {
            header: "Status",
            viewingOption: "Status",
            rowKey: "status",
        },
        {
            header: "",
            viewingOption: "",
            rowKey: "",
        },
    ],
    CREDIT_NOTES_OUTCOME: [
        {
            header: "Contract Ref",
            viewingOption: "Contract reference",
            rowKey: "contractRef",
        },
        {
            header: "Treatment site",
            viewingOption: "Treatment site",
            rowKey: "accountName",
        },
        {
            header: "Infusion date",
            viewingOption: "Infusion date",
            rowKey: "infusionDate",
        },
        {
            header: "Cquence id",
            viewingOption: "Cquence id",
            rowKey: "salessystemOrderId",
        },
        {
            header: "SAP order number",
            viewingOption: "SAP order number",
            rowKey: "sapOrderNo",
        },
        {
            header: "SCP ref",
            viewingOption: "SCP ref",
            rowKey: "scpRefNo",
        },
        {
            header: "Milestone",
            viewingOption: "Milestone",
            rowKey: "milestoneNo",
        },
        {
            header: "Amount",
            viewingOption: "Amount",
            rowKey: "amount",
        },
        {
            header: "PCT.",
            viewingOption: "PCT.",
            rowKey: "percentage",
        },
        {
            header: "Outcome date",
            viewingOption: "Outcome date",
            rowKey: "createdAt",
        },
        {
            header: "Date processed",
            viewingOption: "Date processed",
            rowKey: "processedAt",
        },
        {
            header: "Status",
            viewingOption: "Status",
            rowKey: "status",
        },
        {
            header: "",
        },
    ],
    CREDIT_NOTES_CLAIM: [
        {
            header: "Claim number",
            viewingOption: "Claim number",
            rowKey: "claimNo",
        },
        {
            header: "Account",
            viewingOption: "Account",
            rowKey: "accountName",
        },
        {
            header: "Contract reference",
            viewingOption: "Contract reference",
            rowKey: "contractRef",
        },
        {
            header: "Claim customer reference",
            viewingOption: "Claim customer reference",
            rowKey: "claimCustomerReference",
        },
        {
            header: "Claim status",
            viewingOption: "Claim status",
            rowKey: "claimStatus",
        },
        {
            header: "",
        },
    ],
    INVOICE_RELEASE: [
        {
            header: "Contract Ref",
            viewingOption: "Contract reference",
            rowKey: "contractRef",
        },

        {
            header: "Treatment site",
            viewingOption: "Treatment site",
            rowKey: "accountName",
        },
        {
            header: "Infusion date",
            viewingOption: "Infusion date",
            rowKey: "infusionDate",
        },
        {
            header: "Cquence id",
            viewingOption: "Cquence id",
            rowKey: "salessystemOrderId",
        },
        {
            header: "SAP order number",
            viewingOption: "SAP order number",
            rowKey: "sapOrderNo",
        },
        {
            header: "SCP ref",
            viewingOption: "SCP ref",
            rowKey: "scpRefNo",
        },
        {
            header: "Milestone",
            viewingOption: "Milestone",
            rowKey: "milestoneNo",
        },
        {
            header: "Milestone met",
            viewingOption: "Milestone met",
            rowKey: "milestoneMet",
        },
        {
            header: "Billing block",
            viewingOption: "Billing block",
            rowKey: "billingBlock",
        },

        {
            header: "Date processed",
            viewingOption: "Date processed",
            rowKey: "processedAt",
        },
        {
            header: "Status",
            viewingOption: "Status",
            rowKey: "status",
        },
        {
            header: "",
        },
    ],
    TRANSACTION_VALUES: [
        {
            rowKey: "countryIsoCode",
            header: "Country",
        },
        {
            rowKey: "brandId",
            header: "Brand",
        },
        {
            rowKey: "salesOrg",
            header: "Organisation",
        },
        {
            rowKey: "division",
            header: "Division",
        },
        {
            rowKey: "distributionChannel",
            header: "Distribution channel",
        },
        {
            rowKey: "orderType",
            header: "Order type",
        },
        {
            rowKey: "conditionType",
            header: "Condition",
        },
        {
            rowKey: "bpfId",
            header: "Bpf Id",
        },
        {
            rowKey: "entryType",
            header: "Entry type",
        },
        {
            rowKey: "legalEntity",
            header: "Legal entity",
        },
        {
            rowKey: "fullReportingMrc",
            header: "MRC",
        },
        {
            rowKey: "organizationalUnit",
            header: "Organizational unit",
        },
        {
            rowKey: "transactionType",
            header: "Transaction type",
        },
        {
            rowKey: "debetCredit",
            header: "Debet credit",
        },
        {
            rowKey: "accountType",
            header: "Account type",
        },
        {
            rowKey: "paymentTerms",
            header: "Payment terms",
        },
        {
            rowKey: "paymentMethod",
            header: "Payment method",
        },
        {
            rowKey: "glAccount",
            header: "GL account",
        },

        {
            rowKey: "commodityCode",
            header: "Commodity code",
        },
        {
            rowKey: "companyCode",
            header: "Company code",
        },
        {
            rowKey: "taxCode",
            header: "Tax code",
        },
        {
            rowKey: "referenceDocument",
            header: "Reference document",
        },
        {
            rowKey: "cnReferenceQualifier",
            header: "CN reference qualifier",
        },
        {
            rowKey: "hccProfessionalPayment",
            header: "HCC Professional Payment",
        },
        {
            rowKey: "hccProductRebate",
            header: "HCC Product Rebate",
        },
        {
            rowKey: "hccGovernmentIndicator",
            header: "HCC Government Indicator",
        },
        {
            header: "",
        },
    ],
    BILLING_PLAN: [
        {
            rowKey: "salesOrg",
            viewingOption: "Organisation",
            header: "Organisation",
        },
        {
            rowKey: "documentType",
            viewingOption: "Document Type",
            header: "Document Type",
        },
        {
            rowKey: "division",
            viewingOption: "Division",
            header: "Division",
        },
        {
            rowKey: "planType",
            viewingOption: "Plan Type",
            header: "Plan Type",
        },
        {
            rowKey: "counter",
            viewingOption: "Counter",
            header: "Counter",
        },
        {
            rowKey: "days",
            viewingOption: "Days",
            header: "Days",
        },
        {
            rowKey: "percentage",
            viewingOption: "Percentage",
            header: "Percentage",
        },
        {
            rowKey: "paymentTerms",
            viewingOption: "Payment Terms",
            header: "Payment Terms",
        },
        {
            rowKey: "billingBlock",
            viewingOption: "Billing Block",
            header: "Billing Block",
        },
        {
            header: "",
            viewingOption: "",
            rowKey: "",
        },
    ],
    MILESTONE: [
        {
            rowKey: "milestoneNo",
            viewingOption: "Milestone number",
            header: "Milestone number",
        },
        {
            rowKey: "milestoneDescr",
            viewingOption: "Milestone description",
            header: "Milestone description",
        },
        {
            rowKey: "contractRef",
            viewingOption: "Contract ref.",
            header: "Contract ref.",
        },
        {
            rowKey: "validFrom",
            viewingOption: "Start date",
            header: "Start date",
        },
        {
            rowKey: "validTo",
            viewingOption: "End date",
            header: "End date",
        },
        {
            rowKey: "productName",
            viewingOption: "Product name",
            header: "Product name",
        },
        {
            header: "",
        },
    ],
    MAINTENANCE_FINANCIAL_CONDITIONS: [
        {
            rowKey: "name",
            viewingOption: "Name",
            header: "Name",
        },
        {
            rowKey: "description",
            viewingOption: "Description",
            header: "Description",
        },
        {
            rowKey: "camundaDecisionTableId",
            viewingOption: "DMN ID",
            header: "DMN ID",
        },
        {
            header: "",
        },
    ],
    PRODUCT_CONDITIONS: [
        {
            header: "Name",
            viewingOption: "Name",
            rowKey: "productName",
        },
        {
            header: "Organisation",
            viewingOption: "Organisation",
            rowKey: "salesOrg",
        },
        {
            header: "SAP SKU number",
            viewingOption: "SAP SKU number",
            rowKey: "sapSkuNo",
        },

        {
            header: "Status",
            viewingOption: "Status",
            rowKey: "status",
        },
        {
            header: "Country",
            viewingOption: "Country",
            rowKey: "country",
        },
        {
            header: "",
            viewingOption: "",
            rowKey: "",
        },
    ],

    OUTCOME_FINANCIAL_TRANSACTIONS: [
        {
            header: "",
            rowKey: "",
        },
        {
            header: "Cquence number",
            rowKey: "cquence_number",
        },
        {
            header: "SAP order number",
            rowKey: "sap_order_no",
        },

        {
            header: "Amount",
            rowKey: "amount",
        },
        {
            header: "Measure",
            rowKey: "measure",
        },

        {
            header: "Percentage",
            rowKey: "percentage",
        },

        {
            header: "VAT percentage",
            rowKey: "vat_percentage",
        },

        {
            header: "VAT amount",
            rowKey: "vat_amount",
        },
        {
            header: "Status",
            rowKey: "status",
        },
    ],
    CONTEXT: [
        {
            header: "Name",
            viewingOption: "Name",
            rowKey: "contextName",
        },
        {
            header: "Type",
            viewingOption: "Type",
            rowKey: "type",
        },
        {
            header: "Country",
            viewingOption: "Country",
            rowKey: "country",
        },
        {
            header: "",
            viewingOption: "",
            rowKey: "",
        },
    ],
    REGION: [
        {
            header: "Type",
            viewingOption: "Type",
            rowKey: "type",
        },
        {
            header: "Code",
            viewingOption: "Code",
            rowKey: "code",
        },
        {
            header: "Name",
            viewingOption: "Name",
            rowKey: "name",
        },
        {
            header: "Tender region code",
            viewingOption: "Tender region code",
            rowKey: "tenderRegionCode",
            showForCountries: ["GB"],
        },
        {
            header: "Parent region code",
            viewingOption: "Parent region code",
            rowKey: "parentRegionCode",
            showForCountries: ["GB"],
        },
        {
            header: "Region account field",
            viewingOption: "Region account field",
            rowKey: "regionAccountField",
            showForCountries: ["GB"],
        },
        {
            header: "",
            rowKey: "",
        },
    ],

    EXTERNAL_CODES: [
        {
            header: "Context",
            viewingOption: "Context",
            rowKey: "context",
        },
        {
            header: "External code",
            viewingOption: "External code",
            rowKey: "code",
        },
        {
            header: "External name",
            viewingOption: "External name",
            rowKey: "name",
        },
        {
            header: "",
            rowKey: "",
        },
    ],
    MODEL: [
        {
            header: "Name",
            viewingOption: "Name",
            rowKey: "name",
        },
        {
            header: "Country",
            viewingOption: "Country",
            rowKey: "country",
        },
        {
            header: "Currency",
            viewingOption: "Currency",
            rowKey: "currency",
        },
        {
            header: "Type",
            viewingOption: "Type",
            rowKey: "type",
        },
        {
            header: "Data source",
            viewingOption: "Data source",
            rowKey: "dataSource",
        },
        {
            header: "Price source",
            viewingOption: "Price Source",
            rowKey: "priceSource",
        },
        {
            header: "DMN Id",
            viewingOption: "DMN Id",
            rowKey: "dmnId",
        },
        {
            header: "Account context",
            viewingOption: "Account context",
            rowKey: "accountContext",
        },
        {
            header: "Region context",
            viewingOption: "Region context",
            rowKey: "regionContext",
        },
        {
            header: "Product context",
            viewingOption: "Product context",
            rowKey: "productContext",
        },
        {
            header: "Allowed tolerance percentage on units",
            viewingOption: "Allowed tolerance percentage on units",
            rowKey: "allowedTotalUnitsTolerance",
        },
        {
            header: "No.of linked categories",
            viewingOption: "No.of linked categories",
            rowKey: "nrCategories",
        },
        {
            header: "",
            rowKey: "",
        },
    ],
    CATEGORY: [
        {
            header: "Category name",
            viewingOption: "Category name",
            rowKey: "categoryName",
        },
        {
            header: "Category label",
            viewingOption: "Category label",
            rowKey: "categoryLabel",
        },
        {
            header: "Category value",
            viewingOption: "Category value",
            rowKey: "categoryValue",
        },
        {
            header: "Country",
            viewingOption: "Country",
            rowKey: "country",
        },
        {
            header: "Type",
            viewingOption: "Type",
            rowKey: "type",
        },
        {
            header: "",
            rowKey: "",
        },
    ],
    LINK_CATEGORY: [
        {
            header: "",
            rowKey: "",
        },
        {
            header: "Category name",
            viewingOption: "Category name",
            rowKey: "categoryName",
        },
        {
            header: "Category value",
            viewingOption: "Category value",
            rowKey: "categoryValue",
        },
    ],
    CONTRACT_CATEGORY: [
        {
            header: "Category label",
            viewingOption: "Category label",
            rowKey: "categoryLabel",
        },
        {
            header: "Category value",
            viewingOption: "Category value",
            rowKey: "categoryValue",
        },
        {
            header: "",
            rowKey: "",
        },
    ],
    SCALES_OVERVIEW: [
        {
            header: "Price",
            viewingOption: "Price",
            rowKey: "price",
        },
        {
            header: "Currency",
            viewingOption: "Currency",
            rowKey: "currency",
        },
        {
            header: "Discount percentage",
            viewingOption: "Discount percentage",
            rowKey: "discountPercentage",
        },
        {
            header: "Unit of measure from",
            viewingOption: "Unit of measure from",
            rowKey: "unitOfMeasureFrom",
        },
        {
            header: "Unit of measure to",
            viewingOption: "Unit of measure to",
            rowKey: "unitOfMeasureTo",
        },
        {
            header: "Unit of measure",
            viewingOption: "Unit of measure",
            rowKey: "unitOfMeasure",
        },
        {
            header: "Period from",
            viewingOption: "Period from",
            rowKey: "periodFrom",
        },
        {
            header: "Period to",
            viewingOption: "Period to",
            rowKey: "periodTo",
        },
    ],
    SCALES_WITH_ACTION: [
        {
            header: "Price",
            viewingOption: "Price",
            rowKey: "price",
        },
        {
            header: "Discount percentage",
            viewingOption: "Discount percentage",
            rowKey: "discountPercentage",
        },
        {
            header: "Unit of measure from",
            viewingOption: "Unit of measure from",
            rowKey: "unitOfMeasureFrom",
        },
        {
            header: "Unit of measure to",
            viewingOption: "Unit of measure to",
            rowKey: "unitOfMeasureTo",
        },
        {
            header: "Unit of measure",
            viewingOption: "Unit of measure",
            rowKey: "unitOfMeasure",
        },
        {
            header: "Period from",
            viewingOption: "Period from",
            rowKey: "periodFrom",
        },
        {
            header: "Period to",
            viewingOption: "Period to",
            rowKey: "periodTo",
        },
        {
            header: "",
            rowKey: "",
        },
    ],
    ADVANCED_SCALES_WITH_ACTION: [
        {
            header: "Scale Period from",
            rowKey: "periodFrom",
        },
        {
            header: "Scale Period to",
            rowKey: "periodTo",
        },
        {
            header: "Cumulative period from",
            rowKey: "cumulativePeriodFrom",
        },
        {
            header: "Cumulative period to",
            rowKey: "cumulativePeriodTo",
        },
        {
            header: "From measure",
            rowKey: "unitOfMeasureFrom",
        },
        {
            header: "To measure",
            rowKey: "unitOfMeasureTo",
        },
        {
            header: "Discount",
            rowKey: "discountPercentage",
        },
        {
            header: "Price",
            rowKey: "price",
        },
        {
            header: "",
            rowKey: "",
        },
    ],
    CLAIM: [
        {
            header: "Supplier name",
            viewingOption: "Supplier name",
            rowKey: "supplierName",
            showForCountries: ["DE"],
        },
        {
            header: "Contract reference",
            viewingOption: "Contract reference",
            rowKey: "contractRef",
            showForCountries: ["DE"],
        },
        {
            header: "Claim reference number",
            viewingOption: "Claim reference number",
            rowKey: "claimReferenceNumber",
            showForCountries: ["DE"],
        },
        {
            header: "Claim reference",
            viewingOption: "Claim reference",
            rowKey: "claimReferenceNumber",
            showForCountries: ["GB"],
        },
        {
            header: "Customer/External name",
            viewingOption: "Customer/External name",
            rowKey: "accountName",
            showForCountries: ["GB"],
        },
        {
            header: "Claim type",
            viewingOption: "Claim type",
            rowKey: "datamodelName",
            showForCountries: ["GB"],
        },
        {
            header: "File name",
            viewingOption: "File name",
            rowKey: "fileName",
            showForCountries: ["DE"],
        },
        {
            header: "File date",
            viewingOption: "File date",
            rowKey: "fileDate",
            showForCountries: ["DE"],
        },

        {
            header: "Date from",
            viewingOption: "Date from",
            rowKey: "dateFrom",
            showForCountries: [],
        },
        {
            header: "Date to",
            viewingOption: "Date to",
            rowKey: "dateTo",
            showForCountries: [],
        },
        {
            header: "Claim date",
            viewingOption: "Claim date",
            rowKey: "claimDocumentDate",
            showForCountries: ["GB"],
        },
        {
            header: "Due date",
            viewingOption: "Due date",
            rowKey: "claimDueDate",
            showForCountries: ["GB"],
        },
        {
            header: "Date processed",
            viewingOption: "Date processed",
            rowKey: "dateProcessed",
            showForCountries: ["DE"],
        },
        {
            header: "Partner claim reference",
            viewingOption: "Partner claim reference",
            rowKey: "claimCustomerReference",
            showForCountries: ["GB"],
        },
        {
            header: "Net rebate amount",
            viewingOption: "Net rebate amount",
            rowKey: "totalRebateValue",
            showForCountries: ["GB"],
        },
        {
            header: "Status",
            viewingOption: "Status",
            rowKey: "status",
            showForCountries: [],
        },
        {
            header: "",
            rowKey: "",
            showForCountries: [],
        },
    ],
    PRICE_CORRECTION_CLAIMS: [
        {
            header: "Supplier name",
            viewingOption: "Supplier name",
            rowKey: "supplierName",
            showForCountries: [],
        },
        {
            header: "Contract reference",
            viewingOption: "Contract reference",
            rowKey: "contractRef",
            showForCountries: [],
        },
        {
            header: "File name",
            viewingOption: "File name",
            rowKey: "fileName",
            showForCountries: [],
        },
        {
            header: "File date",
            viewingOption: "File date",
            rowKey: "fileDate",
            showForCountries: [],
        },

        {
            header: "Date from",
            viewingOption: "Date from",
            rowKey: "dateFrom",
            showForCountries: [],
        },
        {
            header: "Date to",
            viewingOption: "Date to",
            rowKey: "dateTo",
            showForCountries: [],
        },
        {
            header: "Date processed",
            viewingOption: "Date processed",
            rowKey: "dateProcessed",
            showForCountries: [],
        },
        {
            header: "Price correction status",
            viewingOption: "Price correction status",
            rowKey: "status",
            showForCountries: [],
        },
        {
            header: "",
            rowKey: "",
            showForCountries: [],
        },
    ],
    CLAIM_CONTRACT: [
        {
            header: "",
        },
        {
            header: "Contract reference",
        },
        {
            header: "start date",
        },
        {
            header: "end date",
        },
        {
            header: "Brand",
        },
        {
            header: "Primary contract partner",
        },
        {
            header: "Categories",
        },
    ],
    BPF_CLAIMS: [
        {
            header: "",
        },
        {
            header: "Supplier name",
        },
        {
            header: "file name",
        },
        {
            header: "file date",
        },
        {
            header: "date from",
        },
        {
            header: "date to",
        },
        {
            header: "date processed",
        },
    ],

    CLAIM_FINANCIAL_TRANSACTIONS: [
        {
            header: "",
        },
        {
            header: "Supplier name",
        },
        {
            header: "file name",
        },
        {
            header: "date from",
        },
        {
            header: "date to",
        },
        {
            header: "total rebate value",
        },
        {
            header: "VAT included",
        },
        {
            header: "status",
        },
    ],
    MAINTENANCE_USERS: [
        {
            header: "First name",
        },
        {
            header: "Last name",
        },
        {
            header: "Email",
        },
        {
            header: "WWID",
        },
        {
            header: "Object ID",
        },
        {
            header: "",
        },
    ],
    BPF_PRICE_CORRECTION_CLAIMS: [
        {
            header: "",
        },
        {
            header: "Supplier name",
            viewingOption: "Supplier name",
            rowKey: "supplierName",
        },
        {
            header: "File name",
            viewingOption: "File name",
            rowKey: "fileName",
        },
        {
            header: "File date",
            viewingOption: "File date",
            rowKey: "fileDate",
        },
    ],
    MAINTENANCE_ROLES: [
        {
            header: "Role Name",
        },
        {
            header: "Role ID",
        },
        {
            header: "Role approval level",
        },
        {
            header: "Country code",
        },
        {
            header: "",
        },
    ],
    BPF_OUTCOME_CONTRACT_SELECTION: [
        {
            header: "",
        },
        {
            header: "Ref",
        },
        {
            header: "Contract partner",
        },
        {
            header: "Additional partners",
        },
        {
            header: "product |& indication",
        },
        {
            header: "valid from |& to",
        },
    ],
};
