import { atom } from "recoil";

/**
 * BPF Outcome type
 */
type BPFOutcome = {
    contractId: string;
    reference: string;
    outcomes: Array<string>;
};

/**
 * select category state
 */
export const selectOutcomesState = atom<Array<BPFOutcome>>({
    key: "selectOutcomesState",
    default: [] as BPFOutcome[],
});
